<template>
  <div id="Vereinsfunktionäre">
    <v-toolbar dark color="primary" class="mb-4 mt-2">
      <v-toolbar-title>Vereinsfunktionäre:</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        text
        @click="viewmodel.state.ui.panels.funktionaere.show = !viewmodel.state.ui.panels.funktionaere.show"
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.funktionaere.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.funktionaere.show">
      <v-card flat
        ><v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="$store.state.data_tables.funktionaere.search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="$store.state.data_tables.funktionaere.headers"
                  :items="$store.state.data_tables.funktionaere.data"
                  :search="$store.state.data_tables.funktionaere.search"
                  sort-by="funktion"
                >
                </v-data-table> </v-card
            ></v-col>
          </v-row> </v-card-text
      ></v-card>
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.funktionaere"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Funktionär anlegen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-pencil-outline</v-icon>
              Funktionär bearbeiten
            </v-tab>
            <v-tab href="#tab-3">
              <v-icon left>mdi-delete-outline</v-icon>
              Funktionär löschen
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="viewmodel.state.ui.tabs.funktionaere" touchless>
            <!-- ---------------------------------- Erster Tab: Funktionäre anlegen -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_funktionaer" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Funktion"
                          clearable
                          counter:45
                          prepend-icon="mdi-account-cog-outline"
                          v-model="viewmodel.form_data.funktionaere.create_funktionaer.data.funktion"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-autocomplete
                          label="Priorität für Stammdatenblatt (0 ist die höchste Prio)"
                          clearable
                          prepend-icon="mdi-view-list-outline"
                          :items="PriorityArray"
                          v-model="viewmodel.form_data.funktionaere.create_funktionaer.data.priority"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateFunktionaer" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Funktionär anlegen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_create_funktionaer.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.funktionaere.create_funktionaer.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.funktionaere.create_funktionaer.alert"
                      >{{ viewmodel.state.ui.forms.funktionaere.create_funktionaer.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Funktionäre bearbeiten -->
            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_update_funktionaer" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Funktionär auswählen"
                          clearable
                          prepend-icon="mdi-account-cog-outline"
                          v-model="viewmodel.form_data.funktionaere.update_funktionaer.data.id"
                          :items="$store.state.data_tables.funktionaere.data"
                          item-text="funktion"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="GetFunktionaerByID"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Funktion"
                          clearable
                          prepend-icon="mdi-account-cog-outline"
                          v-model="viewmodel.form_data.funktionaere.update_funktionaer.data.funktion"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-autocomplete
                          label="Priorität für Stammdatenblatt (0 ist die höchste Prio)"
                          clearable
                          prepend-icon="mdi-view-list-outline"
                          :items="PriorityArray"
                          v-model="viewmodel.form_data.funktionaere.update_funktionaer.data.priority"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateFunktionaer" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Funktionär aktualisieren</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_update_funktionaer.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.funktionaere.update_funktionaer.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.funktionaere.update_funktionaer.alert"
                      >{{ viewmodel.state.ui.forms.funktionaere.update_funktionaer.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: Funktionär löschen -->
            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_funktionaer"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Funktionär auswählen"
                          clearable
                          prepend-icon="mdi-account-cog-outline"
                          v-model="viewmodel.form_data.funktionaere.delete_funktionaer.data.id"
                          :items="$store.state.data_tables.funktionaere.data"
                          item-text="funktion"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          clearable
                          label="Zum Bestätigen das Wort LÖSCHEN eintragen"
                          :rules="viewmodel.form_data.rules.delete_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteFunktionaer" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Funktionär löschen</v-btn
                        ><v-btn color="error" class="ml-4" @click.prevent="$refs.form_delete_funktionaer.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.funktionaere.delete_funktionaer.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.funktionaere.delete_funktionaer.alert"
                      >{{ viewmodel.state.ui.forms.funktionaere.delete_funktionaer.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Funktionaere',
  data: () => ({
    viewmodel: {
      state: {
        ui: {
          forms: {
            funktionaere: {
              create_funktionaer: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              update_funktionaer: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              delete_funktionaer: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            funktionaere: {
              show: false,
            },
          },
          tabs: {
            funktionaere: null,
          },
        },
      },
      form_data: {
        rules: {
          mail_rules: [(v) => !!v || 'Pflichtfeld', (v) => /.+@.+\..+/.test(v) || 'Keine gültige E-Mail Adresse'],
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        funktionaere: {
          create_funktionaer: {
            data: {
              funktion: '',
              priority: 0,
            },
          },
          update_funktionaer: {
            data: {
              id: '',
              funktion: '',
              priority: 0,
            },
          },
          delete_funktionaer: {
            data: {
              id: '',
            },
          },
        },
      },
    },
  }),
  methods: {
    CreateFunktionaer() {
      if (this.$refs.form_create_funktionaer.validate()) {
        const { data } = this.viewmodel.form_data.funktionaere.create_funktionaer;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_funktionaer.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.funktionaere.create_funktionaer.alert = true;
            this.viewmodel.state.ui.forms.funktionaere.create_funktionaer.alert_type = 'success';
            this.viewmodel.state.ui.forms.funktionaere.create_funktionaer.response = response.data.message;
            this.$emit('FunktionaerCreated');
            this.$refs.form_create_funktionaer.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.funktionaere.create_funktionaer.alert = true;
            this.viewmodel.state.ui.forms.funktionaere.create_funktionaer.response = error.response.data.message;
            this.viewmodel.state.ui.forms.funktionaere.create_funktionaer.alert_type = 'error';
          });
      }
    },
    UpdateFunktionaer() {
      if (this.$refs.form_update_funktionaer.validate()) {
        const { data } = this.viewmodel.form_data.funktionaere.update_funktionaer;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_funktionaer.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.funktionaere.update_funktionaer.alert = true;
            this.viewmodel.state.ui.forms.funktionaere.update_funktionaer.alert_type = 'success';
            this.viewmodel.state.ui.forms.funktionaere.update_funktionaer.response = response.data.message;
            this.$emit('FunktionaerUpdated');
            this.$refs.form_update_funktionaer.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.funktionaere.update_funktionaer.alert = true;
            this.viewmodel.state.ui.forms.funktionaere.update_funktionaer.response = error.response.data.message;
            this.viewmodel.state.ui.forms.funktionaere.update_funktionaer.alert_type = 'error';
          });
      }
    },
    DeleteFunktionaer() {
      if (this.$refs.form_delete_funktionaer.validate()) {
        const data = {
          id: this.viewmodel.form_data.funktionaere.delete_funktionaer.data.id,
        };
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_funktionaer.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.funktionaere.delete_funktionaer.alert = true;
            this.viewmodel.state.ui.forms.funktionaere.delete_funktionaer.alert_type = 'success';
            this.viewmodel.state.ui.forms.funktionaere.delete_funktionaer.response = response.data.message;
            this.$emit('FunktionaerDeleted');
            this.$refs.form_delete_funktionaer.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.funktionaere.delete_funktionaer.alert = true;
            this.viewmodel.state.ui.forms.funktionaere.delete_funktionaer.response = this.GetInUseArray(
              error.response.data.message,
              error.response.data.result,
            );
            this.viewmodel.state.ui.forms.funktionaere.delete_funktionaer.alert_type = 'error';
          });
      }
    },
    async GetFunktionaerByID() {
      const data = {
        id: this.viewmodel.form_data.funktionaere.update_funktionaer.data.id,
      };
      if (data.id) {
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/funktionaer_get_funktionaer_by_id.php`,
          data,
        );
        this.viewmodel.form_data.funktionaere.update_funktionaer.data = response.data.result[0];
        this.viewmodel.form_data.funktionaere.update_funktionaer.data.priority = Number(
          response.data.result[0].priority,
        );
      }
    },
    GetInUseArray(response, result) {
      let verein = '';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < result.length; i++) {
        if (result.length - i > 1) {
          verein += `${result[i].verein_name},\n`;
        } else {
          verein += `${result[i].verein_name}.\n`;
        }
      }
      return `${response}\n${verein}`;
    },
  },
  computed: {
    PriorityArray() {
      return Array.from({ length: 101 }, (x, i) => i);
    },
    IsDisabled() {
      if (this.$store.state.current_verein_data.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
};
</script>
