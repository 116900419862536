<template>
  <div id="TrainerPanel">
    <v-toolbar dark color="primary" class="mb-4 mt-2">
      <v-toolbar-title>FAQ/Hilfe/Häufige Fragen</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn fab small text @click="viewmodel.state.ui.panels.faq.show = !viewmodel.state.ui.panels.faq.show"
        ><v-icon size="30">{{ viewmodel.state.ui.panels.faq.show ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.faq.show">
      <v-toolbar flat>
        <v-toolbar-title>Allgemein:</v-toolbar-title>
      </v-toolbar>
      <v-card flat>
        <v-card-text class="black--text">
          <p>Warum ist die Geschlechtsform für die Vereinsfunktionäre ausschließlich männlich?</p>
          <p class="ml-4">
            Aus Gründen der besseren Lesbarkeit wird im Text nur eine Form verwendet. Bei allen personenbezogenen
            Bezeichnungen nimmt die gewählte Formulierung auf alle Geschlechter und Geschlechtsidentitäten bezug.
          </p>
        </v-card-text>
      </v-card>
      <v-toolbar flat>
        <v-toolbar-title>Stammdaten:</v-toolbar-title>
      </v-toolbar>
      <v-card flat>
        <v-card-text class="black--text">
          <p>Was ist eine Wiederherstellungs E-Mail Adresse?</p>
          <p class="ml-4">
            Die Wiederherstellungs E-Mail Adresse gibt an, an welche E-Mail Adresse das neue Initialpasswort versendet
            wird, falls das aktuelle Passwort vergessen wurde und man über die Passwort-vergessen-Funktion ein neues
            Passwort anfordert.
          </p>

          <p>Wozu dienen die Kontakt E-Mail Adresse und die Kontakt Telefonnummer?</p>
          <p class="ml-4">
            Diese Information dient den sportsuchenden Besuchern der Website für die Kontaktaufnahme mit dem Verein. Die
            Trainings/Sportangebote, die über die Suchmaschine gefunden werden können, enthalten immer die Kontaktdaten
            des Vereins. Die Kontaktdaten der Trainer werden jedoch nur dann angezeigt, wenn dieses im Abschnitt
            "Trainer" als öffentlich markiert wurden.
          </p>

          <p>Wie funktionieren Abteilungen?</p>
          <p class="ml-4">
            Abteilungen dienen dazu, den Verwaltungsaufwand, bzw. die Stammdatenpflege zu vereinfachen und den Verein in
            organisatorische Einheiten zu unterteilen. Sobald eine Abteilung durch den Verein angelegt wird, erhält
            diese über eigene Zugangsdaten Zugang zur Website. Somit können z.B. die Trainer oder andere Funktionäre
            selbstständig die Trainingsgruppen und Trainings verwalten. Eine Abteilung ist allerdings nicht zwangsläufig
            auch eine vom Verein angebotene Sportart, sondern kann mehrere Sportarten zusammenfassen. Zum Beispiel kann
            eine mögliche Abteilung "Volleyball" sein, die dann die Sportarten Hallen- und Beachvolleyball anbietet. Die
            Sportarten werden nur im Abschnitt "Trainings" den einzelnen Trainingsgruppen/Mannschaften zugewiesen, sind
            aber "losgelöst" von den Abteilungen. Bei kleineren Vereinen bietet es sich aber natürlich an, die Abteilung
            und die Sportart gleichzusetzen.
          </p>
        </v-card-text>
      </v-card>
      <v-toolbar flat>
        <v-toolbar-title>Trainingsgruppen/Mannschaften:</v-toolbar-title>
      </v-toolbar>
      <v-card flat>
        <v-card-text class="black--text">
          <p>Was ist eine Standardsportstätte?</p>
          <p class="ml-4">
            Die Standardsportstätte gibt an, in welcher Halle/Sportanlage die Trainingsgruppe/Mannschaft normalerweise
            oder am meisten trainiert. Dies ist bei Vereinen relevant, deren Trainingsgruppen/Mannschaften in mehreren
            Hallen/Sportanlagen trainieren. Wenn ein Training angelegt wird, wird für den Ort zuerst die
            Standardsportstätte angeboten, diese kann dort jedoch auch bei Bedarf geändert werden.
          </p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data: () => ({
    viewmodel: {
      state: {
        ui: {
          panels: {
            faq: {
              show: false,
            },
          },
        },
      },
    },
  }),
};
</script>
