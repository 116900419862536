<template>
  <div id="VereinPanel">
    <v-toolbar dark color="primary" class="mb-4">
      <v-toolbar-title>Stammdaten</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn fab small text @click="viewmodel.state.ui.panels.verein.show = !viewmodel.state.ui.panels.verein.show"
        ><v-icon size="30">{{ viewmodel.state.ui.panels.verein.show ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.verein.show">
      <v-row>
        <v-col cols="12">
          <Funktionaere></Funktionaere>
        </v-col>
      </v-row>
      <v-toolbar flat>
        <v-toolbar-title>Vereinsdaten </v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="$store.state.user.is_admin">
          <v-btn color="primary" @click.prevent="CreateStammdaten">Stammdatenblatt erzeugen</v-btn>
        </div>
        <div class="ml-4" v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          <v-btn color="primary" @click.prevent="CreateTrainingsUebersicht">Trainingsangebote als Druckversion</v-btn>
        </div>
      </v-toolbar>
      <v-form ref="form_update_verein" lazy-validation class="pl-4 mt-4">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Vereinsname"
              counter="45"
              clearable
              dense
              :disabled="!$store.state.user.is_admin"
              prepend-icon="mdi-account-outline"
              v-model="$store.state.current_verein_data.verein_name"
              :rules="viewmodel.form_data.rules.generic_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Anschrift"
              counter="45"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-home-city-outline"
              v-model="$store.state.current_verein_data.street"
              :rules="viewmodel.form_data.rules.generic_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="PLZ"
              counter="5"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-home-city-outline"
              v-model="$store.state.current_verein_data.plz"
              :rules="viewmodel.form_data.rules.plz_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Stadt"
              counter="45"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-home-city-outline"
              v-model="$store.state.current_verein_data.city"
              :rules="viewmodel.form_data.rules.generic_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="$store.state.current_verein_data.logo_url"
            ><div class="d-flex flex-grow-1 justify-center">
              <v-img :src="$store.state.current_verein_data.logo_url" max-width="150"></v-img>
            </div>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Wiederherstellungs E-Mail"
              counter="45"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-email-outline"
              v-model="$store.state.current_verein_data.recovery_mail"
              :rules="viewmodel.form_data.rules.mail_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Kontakt E-Mail Adresse"
              counter="45"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-email-outline"
              v-model="$store.state.current_verein_data.mail"
              :rules="viewmodel.form_data.rules.mail_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Kontakt Telefonnummer"
              counter="45"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-phone-outline"
              v-model="$store.state.current_verein_data.phone"
              :rules="viewmodel.form_data.rules.generic_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Homepage"
              counter="250"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-web"
              v-model="$store.state.current_verein_data.homepage_url"
            ></v-text-field
            >WICHTIG: Die URL immer vollständig angeben, z.B. http://www.beispiel.de oder https://beispiel.de und NICHT
            www.beispiel.de</v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-checkbox
              label="Verein ist aktiv"
              dense
              :disabled="!$store.state.user.is_admin"
              v-model="$store.state.current_verein_data.is_active"
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-checkbox
              label="Verein bei Änderung von Trainingszeiten informieren"
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              v-model="$store.state.current_verein_data.inform_on_training_change"
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="$store.state.current_verein_data.date_ssv_beitritt"
                  label="Gründungsdatum"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!$store.state.user.is_admin"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="$store.state.current_verein_data.date_ssv_beitritt"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                @change="save"
              ></v-date-picker> </v-menu
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-checkbox
              label="Führungszeugnis liegt vor"
              dense
              :disabled="!$store.state.user.is_admin"
              v-model="$store.state.current_verein_data.is_fuehrungszeugnis"
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-checkbox
              label="Teilnahme an MARLeFIT"
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              v-model="$store.state.current_verein_data.is_marlefit"
            ></v-checkbox
          ></v-col>
        </v-row>

        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          <v-col cols="12">
            <v-btn color="success" @click.prevent="UpdateVerein" :disabled="IsDisabled"
              ><v-icon left>mdi-check</v-icon>Änderungen speichern/Vereinsdaten bestätigen</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          <v-col cols="12"
            ><v-btn color="error" @click.prevent="$emit('verein-updated')"
              ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
            ></v-col
          >
        </v-row>

        <v-row
          ><v-col cols="12">
            <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="IsLoading">
            </v-progress-linear></v-col
        ></v-row>
        <v-alert
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="viewmodel.state.ui.forms.verein.update_verein.alert_type"
          elevation="2"
          v-model="viewmodel.state.ui.forms.verein.update_verein.alert"
          >{{ viewmodel.state.ui.forms.verein.update_verein.response }}</v-alert
        >
      </v-form>

      <v-toolbar dark color="primary" class="mt-4 mb-2">
        <v-toolbar-title>Uploadbereich</v-toolbar-title>
      </v-toolbar>
      <v-card flat
        ><v-card-text>
          <v-row
            ><v-col cols="12"><h2 class="mt-4">Hochgeladene Dokumente</h2></v-col></v-row
          >

          <v-row v-if="$store.state.current_verein_data.nachweis_gemeinnuetzigkeit_url">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <h3>
                <a :href="$store.state.current_verein_data.nachweis_gemeinnuetzigkeit_url" target="blank"
                  >Gemeinnützigkeitserklärung</a
                >, gültig von {{ $store.state.current_verein_data.stmt_valid_from }} -
                {{ $store.state.current_verein_data.stmt_valid_until }}
              </h3></v-col
            >
          </v-row>
          <v-row v-if="$store.state.current_verein_data.satzung_url">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <h3>
                <a :href="$store.state.current_verein_data.satzung_url" target="blank">Vereinssatzung</a>
              </h3></v-col
            >
          </v-row>
          <v-row v-if="$store.state.current_verein_data.logo_url">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <h3>
                <a
                  :href="$store.state.current_verein_data.logo_url"
                  @mouseleave="viewmodel.state.ui.forms.verein.logo_hover = false"
                  @mouseover="viewmodel.state.ui.forms.verein.logo_hover = true"
                  target="blank"
                  >Vereinslogo/Wappen</a
                >
              </h3>
              <transition name="fade"
                ><v-img
                  v-if="viewmodel.state.ui.forms.verein.logo_hover"
                  :src="$store.state.current_verein_data.logo_url"
                  max-width="250"
                ></v-img
              ></transition>
            </v-col>
          </v-row>
          <v-row v-if="$store.state.current_verein_data.hygiene_concept_url">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <h3>
                <a :href="$store.state.current_verein_data.hygiene_concept_url" target="blank">Hygienekonzept</a>
              </h3></v-col
            >
          </v-row>

          <v-row
            ><v-col cols="12"><h2 class="mt-2">Dokumente hochladen</h2></v-col></v-row
          >
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                clearable
                prepend-icon="mdi-view-list-outline"
                label="Dokument auswählen"
                :items="viewmodel.state.ui.forms.verein.document_types"
                v-model="viewmodel.form_data.verein.update_verein.document_type"
              ></v-autocomplete>
            </v-col>
          </v-row> </v-card-text
      ></v-card>

      <!-- Upload Gemeinnützigkeitserklärung ----------------------------------- -->
      <v-form
        v-if="
          ($store.state.user.is_admin || $store.state.user.is_verein) &&
            viewmodel.form_data.verein.update_verein.document_type === 'Gemeinnützigkeitserklärung'
        "
        ref="form_gemeinnuetzigkeit"
        lazy-validation
        class="pl-4 mt-4"
      >
        <v-row
          ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-file-input
              label="Gemeinnützigkeitserklärung, PDF"
              dense
              truncate-length="15"
              accept="application/pdf"
              v-model="viewmodel.form_data.verein.update_verein.data.gemeinnuetzigkeit_file"
            ></v-file-input></v-col
        ></v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Gültig von: (JJJJ)"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-calendar"
              v-model="$store.state.current_verein_data.stmt_valid_from"
              :rules="viewmodel.form_data.rules.year_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-text-field
              label="Gültig bis: (JJJJ)"
              clearable
              dense
              :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
              prepend-icon="mdi-calendar"
              v-model="$store.state.current_verein_data.stmt_valid_until"
              :rules="viewmodel.form_data.rules.year_rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          <v-col cols="12">
            <v-btn color="success" @click.prevent="UploadGemeinnuetzigkeit" :disabled="IsDisabled"
              ><v-icon left>mdi-check</v-icon>Erklärung hochladen</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein"
          ><v-col
            ><v-btn color="error" @click.prevent="$refs.form_gemeinnuetzigkeit.reset()"
              ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
            ></v-col
          ></v-row
        >

        <v-alert
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.alert_type"
          elevation="2"
          v-model="viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.alert"
          >{{ viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.response }}</v-alert
        >
      </v-form>

      <!-- Upload Vereinslogo ----------------------------------- -->

      <v-form
        v-if="
          ($store.state.user.is_admin || $store.state.user.is_verein) &&
            viewmodel.form_data.verein.update_verein.document_type === 'Vereinslogo/Wappen'
        "
        ref="form_logo"
        lazy-validation
        class="pl-4 mt-4"
      >
        <v-row
          ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-file-input
              label="Vereinslogo (png/jpg/gif), maximal 5 MB"
              dense
              truncate-length="15"
              accept="image/png, image/jpeg, image/jpg, image/gif"
              :rules="viewmodel.form_data.rules.logo_rules"
              v-model="viewmodel.form_data.verein.update_verein.data.logo_url"
            ></v-file-input> </v-col
        ></v-row>
        <v-row v-if="$store.state.current_verein_data.logo_url"
          ><v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <v-img :src="$store.state.current_verein_data.logo_url"></v-img></v-col
        ></v-row>
        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          <v-col cols="12">
            <v-btn color="success" @click.prevent="UploadLogo" :disabled="IsDisabled"
              ><v-icon left>mdi-check</v-icon>Logo hochladen</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein"
          ><v-col
            ><v-btn color="error" @click.prevent="$refs.form_logo.reset()"
              ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
            ></v-col
          ></v-row
        >

        <v-alert
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="viewmodel.state.ui.forms.verein.update_logo.alert_type"
          elevation="2"
          v-model="viewmodel.state.ui.forms.verein.update_logo.alert"
          >{{ viewmodel.state.ui.forms.verein.update_logo.response }}</v-alert
        >
      </v-form>

      <!-- Upload Vereinssatzung ---------------------------- -->

      <v-form
        v-if="
          ($store.state.user.is_admin || $store.state.user.is_verein) &&
            viewmodel.form_data.verein.update_verein.document_type === 'Vereinssatzung'
        "
        ref="form_satzung"
        lazy-validation
        class="pl-4 mt-4"
      >
        <v-row
          ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-file-input
              label="Vereinssatzung, PDF"
              dense
              truncate-length="15"
              accept="application/pdf"
              v-model="viewmodel.form_data.verein.update_verein.data.satzung_file"
            ></v-file-input></v-col
        ></v-row>

        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          <v-col cols="12">
            <v-btn color="success" @click.prevent="UploadSatzung" :disabled="IsDisabled"
              ><v-icon left>mdi-check</v-icon>Vereinssatzung hochladen</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein"
          ><v-col
            ><v-btn color="error" @click.prevent="$refs.form_satzung.reset()"
              ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
            ></v-col
          ></v-row
        >

        <v-alert
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="viewmodel.state.ui.forms.verein.update_satzung.alert_type"
          elevation="2"
          v-model="viewmodel.state.ui.forms.verein.update_satzung.alert"
          >{{ viewmodel.state.ui.forms.verein.update_satzung.response }}</v-alert
        >
      </v-form>

      <!-- Upload Hygienekonzept ---------------------------- -->

      <v-form
        v-if="
          ($store.state.user.is_admin || $store.state.user.is_verein) &&
            viewmodel.form_data.verein.update_verein.document_type === 'Hygienekonzept'
        "
        ref="form_hygienekonzept"
        lazy-validation
        class="pl-4 mt-4"
      >
        <v-row
          ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
            ><v-file-input
              label="Hygienekonzept, PDF"
              dense
              truncate-length="15"
              accept="application/pdf"
              v-model="viewmodel.form_data.verein.update_verein.data.hygiene_concept_file"
            ></v-file-input></v-col
        ></v-row>

        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          <v-col cols="12">
            <v-btn color="success" @click.prevent="UploadHygieneConcept" :disabled="IsDisabled"
              ><v-icon left>mdi-check</v-icon>Hygienekonzept hochladen</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="$store.state.user.is_admin || $store.state.user.is_verein"
          ><v-col
            ><v-btn color="error" @click.prevent="$refs.form_hygienekonzept.reset()"
              ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
            ></v-col
          ></v-row
        >

        <v-alert
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="viewmodel.state.ui.forms.verein.update_hygiene_concept.alert_type"
          elevation="2"
          v-model="viewmodel.state.ui.forms.verein.update_hygiene_concept.alert"
          >{{ viewmodel.state.ui.forms.verein.update_hygiene_concept.response }}</v-alert
        >
      </v-form>

      <!-- -----------------------------------Abteilungen --------------------------- -->

      <div id="Abteilungsübersicht" v-if="$store.state.user.is_admin || $store.state.user.is_verein">
        <v-toolbar dark color="primary" class="mt-4 mb-2">
          <v-toolbar-title>Abteilungen</v-toolbar-title>
        </v-toolbar>
        <v-card flat
          ><v-card-text>
            <h2 class="ml-4 mt-2">Abteilungsübersicht</h2>
            <v-row>
              <v-col cols="12"
                ><v-card flat>
                  <v-card-title>
                    <v-text-field
                      v-model="$store.state.data_tables.abteilungen.search"
                      append-icon="mdi-magnify"
                      label="Suche"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="$store.state.data_tables.abteilungen.headers"
                    :items="$store.state.data_tables.abteilungen.data"
                    :search="$store.state.data_tables.abteilungen.search"
                    sort-by="abteilung_name"
                  >
                  </v-data-table> </v-card
              ></v-col>
            </v-row> </v-card-text
        ></v-card>
        <v-row>
          <v-col cols="12">
            <v-tabs
              v-model="viewmodel.state.ui.tabs.abteilungen"
              background-color="transparent"
              color="primary"
              centered
              grow
              show-arrows
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1">
                <v-icon left>mdi-plus-circle-outline</v-icon>
                Abteilung anlegen
              </v-tab>

              <v-tab href="#tab-2">
                <v-icon left>mdi-pencil-outline</v-icon>
                Abteilung bearbeiten
              </v-tab>
              <v-tab href="#tab-3" v-if="$store.state.user.is_admin">
                <v-icon left>mdi-delete-outline</v-icon>
                Abteilung löschen
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="viewmodel.state.ui.tabs.abteilungen" touchless>
              <!-- ---------------------------------- Erster Tab: Abteilungen anlegen -->
              <v-tab-item :value="'tab-' + 1">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <p>
                          <strong>
                            Hinweis: Eine Abteilung ist nicht gleichzusetzen mit einer Sportart. Das Anlegen einer
                            Abteilung dient in erster Linie nur dazu, einen weiteren Benutzer zu erzeugen, der Trainer,
                            Mannschaften, Trainings und Veranstaltungen verwalten kann. Eine Abteilung kann mehrere
                            Sportarten zusammenfassen. Beispiel: Abteilung Leichtathletik -> Sportarten Hürdenlauf,
                            Zehnkampf etc. Bei nur einer Sportart pro Abteilung ist die Abteilung auch gleichzeitig die
                            Sportart. Die Zuordnung der Sportarten zur Abteilung findet aber ausschließlich über die
                            Mannschaften statt.</strong
                          >
                        </p>
                        <p>
                          Abteilungen können nur durch einen Administrator gelöscht werden. Dazu bitte an
                          info@ssv-marl.de wenden.
                        </p>
                      </v-col>
                    </v-row>
                    <v-form ref="form_create_abteilung" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><v-text-field
                            label="Abteilungsbezeichnung"
                            clearable
                            dense
                            prepend-icon="mdi-account-outline"
                            v-model="viewmodel.form_data.abteilung.create_abteilung.data.abteilung_name"
                            :rules="viewmodel.form_data.rules.abteilung_rules"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><v-text-field
                            label="Wiederherstellungs E-Mail Adresse"
                            clearable
                            dense
                            prepend-icon="mdi-email-outline"
                            v-model="viewmodel.form_data.abteilung.create_abteilung.data.recovery_mail"
                            :rules="viewmodel.form_data.rules.mail_rules"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn color="success" @click.prevent="CreateAbteilung" :disabled="IsDisabled"
                            ><v-icon left>mdi-check</v-icon>Abteilung anlegen</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12"
                          ><v-btn color="error" @click.prevent="$refs.form_create_abteilung.reset()"
                            ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                          ></v-col
                        >
                      </v-row>
                      <v-row
                        ><v-col cols="12">
                          <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="IsLoading">
                          </v-progress-linear></v-col
                      ></v-row>
                      <v-alert
                        border="top"
                        transition="fade-transition"
                        dismissible
                        colored-border
                        :type="viewmodel.state.ui.forms.abteilung.create_abteilung.alert_type"
                        elevation="2"
                        v-model="viewmodel.state.ui.forms.abteilung.create_abteilung.alert"
                        >{{ viewmodel.state.ui.forms.abteilung.create_abteilung.response }}</v-alert
                      >
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- ---------------------------------- Zweiter Tab: Abteilungen bearbeiten -->
              <v-tab-item :value="'tab-' + 2">
                <v-card flat>
                  <v-card-text>
                    <v-form ref="form_update_abteilung" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><v-autocomplete
                            label="Abteilung auswählen:"
                            clearable
                            prepend-icon="mdi-account-outline"
                            v-model="viewmodel.form_data.abteilung.update_abteilung.data.id"
                            :items="$store.state.data_tables.abteilungen.data"
                            item-text="abteilung_name"
                            item-value="id"
                            :rules="viewmodel.form_data.rules.autocomplete_rules"
                            @change="GetAbteilungByID"
                          ></v-autocomplete
                        ></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><v-text-field
                            label="Wiederherstellungs E-Mail Adresse"
                            clearable
                            dense
                            prepend-icon="mdi-email-outline"
                            v-model="viewmodel.form_data.abteilung.update_abteilung.data.recovery_mail"
                            :rules="viewmodel.form_data.rules.mail_rules"
                          ></v-text-field
                        ></v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-btn color="success" @click.prevent="UpdateAbteilung" :disabled="IsDisabled"
                            ><v-icon left>mdi-check</v-icon>Abteilung aktualisieren</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12"
                          ><v-btn color="error" @click.prevent="$refs.form_update_abteilung.reset()"
                            ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                          ></v-col
                        >
                      </v-row>
                      <v-alert
                        border="top"
                        transition="fade-transition"
                        dismissible
                        colored-border
                        :type="viewmodel.state.ui.forms.abteilung.update_abteilung.alert_type"
                        elevation="2"
                        v-model="viewmodel.state.ui.forms.abteilung.update_abteilung.alert"
                        >{{ viewmodel.state.ui.forms.abteilung.update_abteilung.response }}</v-alert
                      >
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- ---------------------------------- Dritter Tab: Abteilungen löschen -->
              <v-tab-item :value="'tab-' + 3">
                <v-card flat>
                  <v-card-text
                    ><v-form ref="form_delete_abteilung"
                      ><v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><v-autocomplete
                            clearable
                            label="Abteilung auswählen:"
                            prepend-icon="mdi-account-outline"
                            v-model="viewmodel.form_data.abteilung.delete_abteilung.data.id"
                            :items="$store.state.data_tables.abteilungen.data"
                            item-text="abteilung_name"
                            item-value="id"
                            :rules="viewmodel.form_data.rules.autocomplete_rules"
                          ></v-autocomplete
                        ></v-col>

                        <v-col cols="12" sm="12" md="12" lg="12" xl="12"
                          ><p style="color: red;">
                            <strong>
                              Achtung, das Löschen einer Abteilung löscht auch sämtliche Trainings, Mannschaften,
                              Veranstaltungen etc. und ist nicht rückgängig zu machen!</strong
                            >
                          </p></v-col
                        >
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><v-text-field
                            clearable
                            label="Zum Bestätigen das Wort LÖSCHEN eintragen"
                            :rules="viewmodel.form_data.rules.delete_rules"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn color="success" @click.prevent="DeleteAbteilung" :disabled="IsDisabled"
                            ><v-icon left>mdi-delete-outline</v-icon>Abteilung löschen</v-btn
                          ></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col cols="12"
                          ><v-btn color="error" @click.prevent="$refs.form_delete_abteilung.reset()"
                            ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                          ></v-col
                        >
                      </v-row>
                      <v-alert
                        border="top"
                        transition="fade-transition"
                        dismissible
                        colored-border
                        :type="viewmodel.state.ui.forms.abteilung.delete_abteilung.alert_type"
                        elevation="2"
                        v-model="viewmodel.state.ui.forms.abteilung.delete_abteilung.alert"
                        >{{ viewmodel.state.ui.forms.abteilung.delete_abteilung.response }}</v-alert
                      >
                    </v-form></v-card-text
                  >
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Funktionaere from './Verein/Funktionaere.vue';
// import Vereinsportarten from './Verein/Vereinsportarten.vue';

const axios = require('axios');

export default {
  name: 'Verein',
  components: { Funktionaere },
  data: () => ({
    menu: false,
    viewmodel: {
      state: {
        is_loading: false,
        ui: {
          forms: {
            verein: {
              logo_hover: false,
              document_types: ['Gemeinnützigkeitserklärung', 'Vereinslogo/Wappen', 'Vereinssatzung', 'Hygienekonzept'],
              update_verein: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  id: '',
                },
              },
              update_gemeinnuetzigkeit: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  id: '',
                },
              },
              update_logo: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  id: '',
                },
              },
              update_satzung: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  id: '',
                },
              },
              update_hygiene_concept: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  id: '',
                },
              },
            },
            abteilung: {
              create_abteilung: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              update_abteilung: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              delete_abteilung: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            verein: {
              show: false,
            },
          },
          tabs: {
            abteilungen: null,
          },
        },
      },
      form_data: {
        rules: {
          password_rules: [(v) => !!v || 'Pflichtfeld'],
          abteilung_rules: [(v) => /^[a-zA-Z0-9-ä-ö-ü]*$/.test(v) || 'Darf keine Leer- oder Sonderzeichen enthalten'],
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          logo_rules: [(v) => (v.size > 0 && v.size < 5000000) || 'Pflichtfeld'],
          verein_id_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 7) || 'Falsche Anzahl von Zeichen (ungleich 7)',
          ],
          year_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 4) || 'Falsche Anzahl von Zeichen (ungleich 4)',
          ],
          plz_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 5) || 'Falsche Anzahl von Zeichen (ungleich 5)',
          ],
          mail_rules: [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Keine gültige E-Mail Adresse'],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        verein: {
          update_verein: {
            document_type: '',
            data: {
              verein_id: '',
              verein_name: '',
              is_verein: '',
              is_abteilung: '',
              street: '',
              plz: '',
              city: '',
              logo_url: [],
              recovery_mail: '',
              gemeinnuetzigkeit_url: '',
              gemeinnuetzigkeit_file: [],
              hygiene_concept_file: [],
              hygiene_concept_url: null,
              satzung_url: '',
              satzung_file: [],
              is_fuehrungszeugnis: '',
              is_marlefit: '',
              is_active: '',
              date_ssv_beitritt: '',
              inform_on_training_change: '',
            },
          },
        },
        abteilung: {
          create_abteilung: {
            data: {
              abteilung_name: '',
              recovery_mail: '',
            },
          },
          update_abteilung: {
            data: {
              id: '',
              abteilung_name: '',
              recovery_mail: '',
            },
          },
          delete_abteilung: {
            data: {
              id: '',
            },
          },
        },
      },
    },
  }),
  computed: {
    IsLoading() {
      return this.viewmodel.state.is_loading;
    },
    StammdatenReportURL() {
      // eslint-disable-next-line no-useless-concat
      return `${`${process.env.VUE_APP_APIBASEURL}` + '/verein_create_stammdaten.php'}?verein_id=${
        this.$store.state.current_verein_data.verein_id
      }`;
    },
    TrainingsUebersichtURL() {
      // eslint-disable-next-line no-useless-concat
      return `${`${process.env.VUE_APP_APIBASEURL}` + '/training_create_trainingsuebersicht.php'}?verein_id=${
        this.$store.state.current_verein_data.verein_id
      }`;
    },
    IsDisabled() {
      if (this.$store.state.user.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  watch: {
    menu(val) {
      // eslint-disable-next-line no-unused-expressions, no-return-assign
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    UpdateVerein() {
      if (this.$refs.form_update_verein.validate()) {
        this.viewmodel.state.is_loading = true;
        const data = this.$store.state.current_verein_data;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_verein.php`, data)
          .then((response) => {
            this.viewmodel.state.is_loading = false;
            this.viewmodel.state.ui.forms.verein.update_verein.alert = true;
            this.viewmodel.state.ui.forms.verein.update_verein.alert_type = 'success';
            this.viewmodel.state.ui.forms.verein.update_verein.response = response.data.message;
            this.$emit('verein-updated');
            this.$refs.form_update_verein.reset();
          })
          .catch((error) => {
            this.viewmodel.state.is_loading = false;
            this.viewmodel.state.ui.forms.verein.update_verein.alert = true;
            this.viewmodel.state.ui.forms.verein.update_verein.response = error.response.data.message;
            this.viewmodel.state.ui.forms.verein.update_verein.alert_type = 'error';
          });
      }
    },
    CreateAbteilung() {
      if (this.$refs.form_create_abteilung.validate()) {
        this.viewmodel.state.is_loading = true;
        const { data } = this.viewmodel.form_data.abteilung.create_abteilung;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_abteilung.php`, {
            data,
            verein_id: this.$store.state.current_verein.verein_id,
          })
          .then((response) => {
            this.viewmodel.state.is_loading = false;
            this.viewmodel.state.ui.forms.abteilung.create_abteilung.alert = true;
            this.viewmodel.state.ui.forms.abteilung.create_abteilung.alert_type = 'success';
            this.viewmodel.state.ui.forms.abteilung.create_abteilung.response = response.data.message;
            this.$emit('AbteilungCreated');
            this.$refs.form_create_abteilung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.is_loading = false;
            this.viewmodel.state.ui.forms.abteilung.create_abteilung.alert = true;
            this.viewmodel.state.ui.forms.abteilung.create_abteilung.response = error.response.data.message;
            this.viewmodel.state.ui.forms.abteilung.create_abteilung.alert_type = 'error';
          });
      }
    },
    UpdateAbteilung() {
      if (this.$refs.form_update_abteilung.validate()) {
        const { data } = this.viewmodel.form_data.abteilung.update_abteilung;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_abteilung.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.abteilung.update_abteilung.alert = true;
            this.viewmodel.state.ui.forms.abteilung.update_abteilung.alert_type = 'success';
            this.viewmodel.state.ui.forms.abteilung.update_abteilung.response = response.data.message;
            this.$emit('AbteilungUpdated');
            this.$refs.form_update_abteilung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.abteilung.update_abteilung.alert = true;
            this.viewmodel.state.ui.forms.abteilung.update_abteilung.response = error.response.data.message;
            this.viewmodel.state.ui.forms.abteilung.update_abteilung.alert_type = 'error';
          });
      }
    },
    DeleteAbteilung() {
      if (this.$refs.form_delete_abteilung.validate()) {
        const { data } = this.viewmodel.form_data.abteilung.delete_abteilung;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_abteilung.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.abteilung.delete_abteilung.alert = true;
            this.viewmodel.state.ui.forms.abteilung.delete_abteilung.alert_type = 'success';
            this.viewmodel.state.ui.forms.abteilung.delete_abteilung.response = response.data.message;
            this.$emit('AbteilungDeleted');
            this.$refs.form_delete_abteilung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.abteilung.delete_abteilung.alert = true;
            this.viewmodel.state.ui.forms.abteilung.delete_abteilung.response = error.response.data.message;
            this.viewmodel.state.ui.forms.abteilung.delete_abteilung.alert_type = 'error';
          });
      }
    },
    UploadGemeinnuetzigkeit() {
      if (this.$refs.form_gemeinnuetzigkeit.validate()) {
        const data = this.$store.state.current_verein_data;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.verein.update_verein.data.gemeinnuetzigkeit_file);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/upload_gemeinnuetzigkeit.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.alert = true;
            this.viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.alert_type = 'success';
            this.viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.response = response.data.message;
            this.$emit('GemeinnuetzigkeitUploaded');
            this.$refs.form_gemeinnuetzigkeit.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.alert = true;
            this.viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.response = error.response.data.message;
            this.viewmodel.state.ui.forms.verein.update_gemeinnuetzigkeit.alert_type = 'error';
          });
      }
    },
    UploadLogo() {
      if (this.$refs.form_logo.validate()) {
        const data = this.$store.state.current_verein_data;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.verein.update_verein.data.logo_url);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/upload_logo.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.verein.update_logo.alert = true;
            this.viewmodel.state.ui.forms.verein.update_logo.alert_type = 'success';
            this.viewmodel.state.ui.forms.verein.update_logo.response = response.data.message;
            this.$emit('LogoUploaded');
            this.$refs.form_satzung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.verein.update_logo.alert = true;
            this.viewmodel.state.ui.forms.verein.update_logo.response = error.response.data.message;
            this.viewmodel.state.ui.forms.verein.update_logo.alert_type = 'error';
          });
      }
    },
    UploadSatzung() {
      if (this.$refs.form_satzung.validate()) {
        const data = this.$store.state.current_verein_data;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.verein.update_verein.data.satzung_file);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/upload_satzung.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.verein.update_satzung.alert = true;
            this.viewmodel.state.ui.forms.verein.update_satzung.alert_type = 'success';
            this.viewmodel.state.ui.forms.verein.update_satzung.response = response.data.message;
            this.$emit('SatzungUploaded');
            this.$refs.form_satzung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.verein.update_satzung.alert = true;
            this.viewmodel.state.ui.forms.verein.update_satzung.response = error.response.data.message;
            this.viewmodel.state.ui.forms.verein.update_satzung.alert_type = 'error';
          });
      }
    },
    UploadHygieneConcept() {
      if (this.$refs.form_hygienekonzept.validate()) {
        const data = this.$store.state.current_verein_data;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.verein.update_verein.data.hygiene_concept_file);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/upload_hygiene_concept.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.verein.update_hygiene_concept.alert = true;
            this.viewmodel.state.ui.forms.verein.update_hygiene_concept.alert_type = 'success';
            this.viewmodel.state.ui.forms.verein.update_hygiene_concept.response = response.data.message;
            this.$emit('HygieneConceptUploaded');
            this.$refs.form_satzung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.verein.update_hygiene_concept.alert = true;
            this.viewmodel.state.ui.forms.verein.update_hygiene_concept.response = error.response.data.message;
            this.viewmodel.state.ui.forms.verein.update_hygiene_concept.alert_type = 'error';
          });
      }
    },
    async GetAbteilungByID() {
      const { data } = this.viewmodel.form_data.abteilung.update_abteilung;
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/abteilung_get_abteilung_by_id.php`, data);
      this.viewmodel.form_data.abteilung.update_abteilung.data = response.data.result[0];
      this.$emit('VereinsportartCreated');
    },
    CreateStammdaten() {
      axios({
        url: this.StammdatenReportURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        const date = new Date();
        const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          // eslint-disable-next-line prefer-template
          'Stammdatenblatt ' + this.$store.state.current_verein_data.verein_name + ' ' + today.toString() + '.pdf',
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    CreateTrainingsUebersicht() {
      axios({
        url: this.TrainingsUebersichtURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        const date = new Date();
        const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          // eslint-disable-next-line prefer-template
          'Trainingsübersicht ' + this.$store.state.current_verein_data.verein_name + ' ' + today.toString() + '.pdf',
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
