<template>
  <div id="SportstättenübersichtPanel">
    <v-toolbar dark color="primary" class="mb-4">
      <v-toolbar-title>Sportstättenübersicht</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        text
        @click="
          viewmodel.state.ui.panels.sportstaettenuebersicht.show = !viewmodel.state.ui.panels.sportstaettenuebersicht
            .show
        "
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.sportstaettenuebersicht.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.sportstaettenuebersicht.show">
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.sportstaettenuebersicht"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-table</v-icon>
              Sportstätten anzeigen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Sportstätte anlegen
            </v-tab>

            <v-tab href="#tab-3">
              <v-icon left>mdi-pencil-outline</v-icon>
              Sportstätte bearbeiten
            </v-tab>
            <v-tab href="#tab-4">
              <v-icon left>mdi-delete-outline</v-icon>
              Sportstätte löschen
            </v-tab>
            <v-tab href="#tab-5">
              <v-icon left>mdi-table</v-icon>
              Belegungsplan
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="viewmodel.state.ui.tabs.sportstaettenuebersicht">
            <!-- ---------------------------------- Erster Tab: Sportstättenübersicht -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text
                  ><div id="Vereinsübersicht">
                    <v-row>
                      <v-col cols="12"
                        ><v-card flat>
                          <v-card-title>
                            <v-text-field
                              v-model="$store.state.data_tables.sportstaetten.search"
                              append-icon="mdi-magnify"
                              label="Suche"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-card-title>
                          <v-data-table
                            :headers="$store.state.data_tables.sportstaetten.headers"
                            :items="$store.state.data_tables.sportstaetten.data"
                            :search="$store.state.data_tables.sportstaetten.search"
                          >
                          </v-data-table> </v-card
                      ></v-col>
                    </v-row></div
                ></v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Sportstätten anlegen -->

            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_sportstaette">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Sportstätte:"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-home-outline"
                          v-model="
                            viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.sportstaette_name
                          "
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Anschrift:"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-map-marker-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.street"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          counter="5"
                          label="PLZ:"
                          clearable
                          dense
                          prepend-icon="mdi-map-marker-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.plz"
                          :rules="viewmodel.form_data.rules.plz_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Stadt:"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.city"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Stadtteil"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="viewmodel.state.ui.forms.sportstaettenuebersicht.staddteile"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.stadtteil"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Hallentyp"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          :items="viewmodel.state.ui.forms.sportstaettenuebersicht.hallentyp"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.hallentyp"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          label="Hat ein Lehrschwimmbecken"
                          v-model="
                            viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.is_lehrschwimmbecken
                          "
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Weitere Sportmöglichkeiten, optional (z.B. Freibad, Hallenbad, Gymnastikraum, Tennisplatz, Außensportanlagen, Minigolfplatz, Reitplatz etc.)"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.create_sportstaette.data.zusatz_1"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateSportstaette" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Sportstätte anlegen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col
                        ><v-btn color="error" @click.prevent="$refs.form_create_sportstaette.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.alert"
                      >{{ viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: Sportstätten bearbeiten -->

            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_update_sportstaette"
                    ><v-row>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          clearable
                          label="Sportstätte auswählen:"
                          prepend-icon="mdi-home-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.id"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          @change="GetSportstaetteByID"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"></v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="Name"
                          clearable
                          prepend-icon="mdi-home-outline"
                          counter="45"
                          v-model="
                            viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.sportstaette_name
                          "
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field></v-col
                      ><v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="Anschrift"
                          clearable
                          counter="45"
                          prepend-icon="mdi-map-marker-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.street"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field></v-col
                      ><v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="PLZ"
                          clearable
                          counter="5"
                          prepend-icon="mdi-map-marker-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.plz"
                          :rules="viewmodel.form_data.rules.plz_rules"
                        ></v-text-field></v-col
                      ><v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="Stadt"
                          clearable
                          counter="45"
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.city"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Stadtteil"
                          clearable
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="viewmodel.state.ui.forms.sportstaettenuebersicht.staddteile"
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.stadtteil"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Hallentyp"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          :items="viewmodel.state.ui.forms.sportstaettenuebersicht.hallentyp"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.hallentyp"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          label="Hat ein Lehrschwimmbecken"
                          v-model="
                            viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.is_lehrschwimmbecken
                          "
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Weitere Sportmöglichkeiten, optional (z.B. Freibad, Hallenbad, Gymnastikraum, Tennisplatz, Außensportanlagen, Minigolfplatz, Reitplatz etc.)"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.zusatz_1"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateSportstaette" :disabled="IsDisabled"
                          ><v-icon left>mdi-update</v-icon>Änderungen speichern</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col
                        ><v-btn color="error" @click.prevent="$refs.form_update_sportstaette.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    ><v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.alert"
                      >{{ viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Vierter Tab: Sportstätten löschen -->

            <v-tab-item :value="'tab-' + 4">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_sportstaette"
                    ><v-row>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          clearable
                          label="Sportstätte auswählen"
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette.data.id"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          @change="DeleteSportstaette_GetSportstaetteByID"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="Sportstätte:"
                          filled
                          readonly
                          prepend-icon="mdi-home-city-outline"
                          v-model="
                            viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette.data.sportstaette_name
                          "
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="Anschrift:"
                          filled
                          readonly
                          prepend-icon="mdi-map-marker-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette.data.street"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="PLZ:"
                          filled
                          readonly
                          prepend-icon="mdi-map-marker-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette.data.plz"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-text-field
                          label="Stadt:"
                          filled
                          readonly
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette.data.city"
                        ></v-text-field></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteSportstaette" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Sportstätte löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col
                        ><v-btn color="error" @click.prevent="$refs.form_delete_sportstaette.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                  </v-form>
                  <v-alert
                    border="top"
                    transition="fade-transition"
                    dismissible
                    colored-border
                    :type="viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.alert_type"
                    elevation="2"
                    v-model="viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.alert"
                    >{{ viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.response }}</v-alert
                  ></v-card-text
                >
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Fünfter Tab: Belegungsplan -->

            <v-tab-item :value="'tab-' + 5">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_belegungsplan"
                    ><v-row>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          clearable
                          label="Sportstätte auswählen"
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.sportstaettenuebersicht.belegungsplan.data.id"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="CreateDynamicBelegungsplan"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"></v-col>
                    </v-row>

                    <!-- Dynamischer Belegungsplan ---------------------------------- -->

                    <v-row style="font-size: 16px;">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-row><v-col cols="12">Montag</v-col></v-row>
                        <v-row
                          v-for="(object, i) in viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data
                            .mon"
                          :key="i"
                          ><v-col cols="12">
                            {{ object.training }}<br />
                            {{ object.verein_name }}<br />
                            <v-tooltip v-model="object.show" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">Details </span>
                              </template>
                              <span>
                                Trainingsgruppe: {{ object.mannschaft_name }}<br />
                                Hallenteil: {{ object.hallenteil_mon }}<br />
                                Trainer: {{ object.trainer_name }}<br />
                                E-Mail: {{ object.mail }}<br />
                                Telefon: {{ object.phone }}<br />
                              </span>
                            </v-tooltip> </v-col
                        ></v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-row><v-col cols="12">Dienstag</v-col></v-row>
                        <v-row
                          v-for="(object, i) in viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data
                            .tue"
                          :key="i"
                          ><v-col cols="12">
                            {{ object.training }}<br />
                            {{ object.verein_name }}<br />
                            <v-tooltip v-model="object.show" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">Details </span>
                              </template>
                              <span>
                                Trainingsgruppe: {{ object.mannschaft_name }}<br />
                                Hallenteil: {{ object.hallenteil_tue }}<br />
                                Trainer: {{ object.trainer_name }}<br />
                                E-Mail: {{ object.mail }}<br />
                                Telefon: {{ object.phone }}<br />
                              </span>
                            </v-tooltip> </v-col
                        ></v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-row><v-col cols="12">Mittwoch</v-col></v-row>
                        <v-row
                          v-for="(object, i) in viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data
                            .wed"
                          :key="i"
                          ><v-col cols="12">
                            {{ object.training }}<br />
                            {{ object.verein_name }}<br />
                            <v-tooltip v-model="object.show" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">Details </span>
                              </template>
                              <span>
                                Trainingsgruppe: {{ object.mannschaft_name }}<br />
                                Hallenteil: {{ object.hallenteil_wed }}<br />
                                Trainer: {{ object.trainer_name }}<br />
                                E-Mail: {{ object.mail }}<br />
                                Telefon: {{ object.phone }}<br />
                              </span>
                            </v-tooltip> </v-col
                        ></v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-row><v-col cols="12">Donnerstag</v-col></v-row>
                        <v-row
                          v-for="(object, i) in viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data
                            .thu"
                          :key="i"
                          ><v-col cols="12">
                            {{ object.training }}<br />
                            {{ object.verein_name }}<br />
                            <v-tooltip v-model="object.show" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">Details </span>
                              </template>
                              <span>
                                Trainingsgruppe: {{ object.mannschaft_name }}<br />
                                Hallenteil: {{ object.hallenteil_thu }}<br />
                                Trainer: {{ object.trainer_name }}<br />
                                E-Mail: {{ object.mail }}<br />
                                Telefon: {{ object.phone }}<br />
                              </span>
                            </v-tooltip> </v-col
                        ></v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-row><v-col cols="12">Freitag</v-col></v-row>
                        <v-row
                          v-for="(object, i) in viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data
                            .fri"
                          :key="i"
                          ><v-col cols="12">
                            {{ object.training }}<br />
                            {{ object.verein_name }}<br />
                            <v-tooltip v-model="object.show" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">Details </span>
                              </template>
                              <span>
                                Trainingsgruppe: {{ object.mannschaft_name }}<br />
                                Hallenteil: {{ object.hallenteil_fri }}<br />
                                Trainer: {{ object.trainer_name }}<br />
                                E-Mail: {{ object.mail }}<br />
                                Telefon: {{ object.phone }}<br />
                              </span>
                            </v-tooltip> </v-col
                        ></v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="1" lg="1" xl="1">
                        <v-row><v-col cols="12">Samstag</v-col></v-row>
                        <v-row
                          v-for="(object, i) in viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data
                            .sat"
                          :key="i"
                          ><v-col cols="12">
                            {{ object.training }}<br />
                            {{ object.verein_name }}<br />
                            <v-tooltip v-model="object.show" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">Details </span>
                              </template>
                              <span>
                                Trainingsgruppe: {{ object.mannschaft_name }}<br />
                                Hallenteil: {{ object.hallenteil_sat }}<br />
                                Trainer: {{ object.trainer_name }}<br />
                                E-Mail: {{ object.mail }}<br />
                                Telefon: {{ object.phone }}<br />
                              </span>
                            </v-tooltip> </v-col
                        ></v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="1" lg="1" xl="1">
                        <v-row><v-col cols="12">Sonntag</v-col></v-row>
                        <v-row
                          v-for="(object, i) in viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data
                            .sun"
                          :key="i"
                          ><v-col cols="12">
                            {{ object.training }}<br />
                            {{ object.verein_name }}<br />
                            <v-tooltip v-model="object.show" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">Details </span>
                              </template>
                              <span>
                                Trainingsgruppe: {{ object.mannschaft_name }}<br />
                                Hallenteil: {{ object.hallenteil_sun }}<br />
                                Trainer: {{ object.trainer_name }}<br />
                                E-Mail: {{ object.mail }}<br />
                                Telefon: {{ object.phone }}<br />
                              </span>
                            </v-tooltip> </v-col
                        ></v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateBelegungsplan"
                          ><v-icon left>mdi-table</v-icon>Druckversion anzeigen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col
                        ><v-btn color="error" @click.prevent="$refs.form_belegungsplan.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Sportstaettenuebersicht',
  data: () => ({
    show: false,
    viewmodel: {
      state: {
        ui: {
          forms: {
            sportstaettenuebersicht: {
              hallentyp: ['Einfachsporthalle', 'Zweifachsporthalle', 'Dreifachsportalle', 'Außensportanlage'],
              staddteile: [
                'Alt-Marl',
                'Brassert',
                'Marl-Hamm',
                'Drewer',
                'Hüls',
                'Marl-Polsum',
                'Sinsen',
                'Lenkerbeck',
                'Stadtkern',
                'Andere',
              ],
              create_sportstaette: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              update_sportstaette: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  id: '',
                },
              },
              delete_sportstaette: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            sportstaettenuebersicht: {
              show: false,
            },
          },
          tabs: {
            sportstaettenuebersicht: null,
          },
        },
      },
      form_data: {
        rules: {
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          verein_id_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 7) || 'Falsche Anzahl von Zeichen (ungleich 7)',
          ],
          plz_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 5) || 'Falsche Anzahl von Zeichen (ungleich 5)',
          ],
          mail_rules: [(v) => !!v || 'Pflichtfeld', (v) => /.+@.+\..+/.test(v) || 'Keine gültige E-Mail Adresse'],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        sportstaettenuebersicht: {
          create_sportstaette: {
            data: {
              sportstaette_name: '',
              street: '',
              plz: '',
              city: '',
              stadtteil: '',
              hallentyp: '',
              is_lehrschwimmbecken: null,
              zusatz_1: '',
            },
            valid: false,
          },
          update_sportstaette: {
            data: {
              id: '',
              sportstaette_name: '',
              street: '',
              plz: '',
              city: '',
              stadtteil: '',
              hallentyp: '',
              is_lehrschwimmbecken: null,
              zusatz_1: '',
            },
            valid: false,
          },
          delete_sportstaette: {
            data: {
              id: '',
              sportstaette_name: '',
              street: '',
              plz: '',
              city: '',
            },
            valid: false,
          },
          belegungsplan: {
            data: {
              id: '',
            },
          },
          dynamic_belegungsplan: {
            data: {
              mon: '',
              tue: '',
              wed: '',
              thu: '',
              fri: '',
              sat: '',
              sun: '',
            },
          },
        },
      },
    },
  }),
  computed: {
    BelegungsplanURL() {
      // eslint-disable-next-line no-useless-concat
      return `${`${process.env.VUE_APP_APIBASEURL}` +
        '/sportstaettenuebersicht_create_belegungsplan.php'}?sportstaette_id=${
        this.viewmodel.form_data.sportstaettenuebersicht.belegungsplan.data.id
      }`;
    },
    IsDisabled() {
      if (this.$store.state.current_verein_data.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  methods: {
    CreateSportstaette() {
      if (this.$refs.form_create_sportstaette.validate()) {
        const { data } = this.viewmodel.form_data.sportstaettenuebersicht.create_sportstaette;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_sportstaette.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.alert = true;
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.alert_type = 'success';
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.response = response.data.message;
            this.$refs.form_create_sportstaette.reset();
            this.$emit('SportstaetteCreated');
            // this.Sportstaettenuebersicht_GetAllSportstaetten();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.alert = true;
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.response =
              error.response.data.message;
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.create_sportstaette.alert_type = 'error';
          });
      }
    },
    UpdateSportstaette() {
      if (this.$refs.form_update_sportstaette.validate()) {
        const { data } = this.viewmodel.form_data.sportstaettenuebersicht.update_sportstaette;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_sportstaette.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.alert = true;
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.alert_type = 'success';
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.response = response.data.message;
            this.$refs.form_update_sportstaette.reset();
            this.$emit('SportstaetteUpdated');
            // this.Sportstaettenuebersicht_GetAllSportstaetten();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.alert = true;
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.response =
              error.response.data.message;
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.update_sportstaette.alert_type = 'error';
          });
      }
    },
    DeleteSportstaette() {
      if (this.$refs.form_delete_sportstaette.validate()) {
        const { data } = this.viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_sportstaette.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.alert = true;
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.alert_type = 'success';
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.response = response.data.message;
            this.$refs.form_delete_sportstaette.reset();
            this.$emit('SportstaetteDeleted');
            // this.Sportstaettenuebersicht_GetAllSportstaetten();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.alert = true;
            // eslint-disable-next-line max-len
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.response = this.GetInUseArray(
              error.response.data.message,
              error.response.data.result,
            );
            this.viewmodel.state.ui.forms.sportstaettenuebersicht.delete_sportstaette.alert_type = 'error';
          });
      }
    },
    async GetSportstaetteByID() {
      const { data } = this.viewmodel.form_data.sportstaettenuebersicht.update_sportstaette;
      if (data.id) {
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/sportstaettenuebersicht_GetSportstaetteByID.php`,
          data,
        );
        // eslint-disable-next-line prefer-destructuring
        this.viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data = response.data.result[0];
        if (this.viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.is_lehrschwimmbecken === '1') {
          this.viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.is_lehrschwimmbecken = true;
        } else {
          this.viewmodel.form_data.sportstaettenuebersicht.update_sportstaette.data.is_lehrschwimmbecken = false;
        }
      } else {
        this.$refs.form_update_sportstaette.reset();
      }
    },
    async DeleteSportstaette_GetSportstaetteByID() {
      const { data } = this.viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette;
      if (data.id) {
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/sportstaettenuebersicht_GetSportstaetteByID.php`,
          data,
        );
        this.viewmodel.form_data.sportstaettenuebersicht.delete_sportstaette.data = response.data.result[0];
      }
    },
    async CreateDynamicBelegungsplan() {
      const { data } = this.viewmodel.form_data.sportstaettenuebersicht.belegungsplan;
      if (data.id) {
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/sportstaettenuebersicht_create_dynamic_belegungsplan.php`,
          data,
        );
        this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data = response.data;
        for (
          let i = 0;
          i < this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.mon.length;
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.mon[i].show = false;
        }
        for (
          let i = 0;
          i < this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.tue.length;
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.tue[i].show = false;
        }
        for (
          let i = 0;
          i < this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.wed.length;
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.wed[i].show = false;
        }
        for (
          let i = 0;
          i < this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.thu.length;
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.thu[i].show = false;
        }
        for (
          let i = 0;
          i < this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.fri.length;
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.fri[i].show = false;
        }
        for (
          let i = 0;
          i < this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.sat.length;
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.sat[i].show = false;
        }
        for (
          let i = 0;
          i < this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.sun.length;
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          this.viewmodel.form_data.sportstaettenuebersicht.dynamic_belegungsplan.data.sun[i].show = false;
        }
      }
    },
    GetInUseArray(response, result) {
      let vereine = '';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < result.length; i++) {
        if (result.length - i > 1) {
          vereine += `${result[i].verein_name}/${result[i].abteilung_name}/${result[i].mannschaft_name}, \n`;
        } else {
          vereine += `${result[i].verein_name}/${result[i].abteilung_name}/${result[i].mannschaft_name}. \n`;
        }
      }
      return `${response}\n${vereine}`;
    },
    CreateBelegungsplan() {
      axios({
        url: this.BelegungsplanURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        const date = new Date();
        const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          // eslint-disable-next-line prefer-template
          'Belegungsplan ' + today.toString() + '.pdf',
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
