<template>
  <div id="TrainingPanel">
    <v-toolbar dark color="primary" class="mb-4 mt-2">
      <v-toolbar-title>Trainings</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        text
        @click="viewmodel.state.ui.panels.trainings.show = !viewmodel.state.ui.panels.trainings.show"
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.trainings.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.trainings.show">
      <v-card flat
        ><v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="$store.state.data_tables.trainings.search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  :headers="$store.state.data_tables.trainings.headers"
                  :items="$store.state.data_tables.trainings.data"
                  :search="$store.state.data_tables.trainings.search"
                  sort-by="mannschaft_name"
                >
                </v-data-table> </v-card
            ></v-col>
          </v-row> </v-card-text
      ></v-card>

      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.trainings"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Training anlegen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-pencil-outline</v-icon>
              Training bearbeiten
            </v-tab>
            <v-tab href="#tab-3">
              <v-icon left>mdi-delete-outline</v-icon>
              Training löschen
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="viewmodel.state.ui.tabs.trainings" touchless>
            <!-- ---------------------------------- Erster Tab: Training anlegen -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_training" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Mannschaft auswählen"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainings.create_training.data.mannschaft_id"
                          :items="$store.state.data_tables.mannschaften.data"
                          item-text="mannschaft_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="GetTrainerByID"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Trainer"
                          disabled
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainings.create_training.data.trainer_id"
                          :items="$store.state.data_tables.trainer.data"
                          item-text="trainer_name"
                          item-value="id"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-switch
                          label="Montags"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.mon"
                          @click="GetDefaultSportstaetteIDMon"
                        ></v-switch
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.mon"
                        ><v-menu
                          ref="menu_mon_from"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.mon_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.mon_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.mon_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.mon_from"
                            v-model="viewmodel.form_data.trainings.create_training.data.mon_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_mon_from.save(viewmodel.form_data.trainings.create_training.data.mon_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.mon"
                        ><v-menu
                          ref="menu_mon_to"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.mon_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.mon_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.mon_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.mon_to"
                            v-model="viewmodel.form_data.trainings.create_training.data.mon_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_mon_to.save(viewmodel.form_data.trainings.create_training.data.mon_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.mon"
                      >
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.create_training.data.sportstaette_id_mon"
                        ></v-autocomplete
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.mon"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.create_training.data.hallenteil_mon"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-switch
                          label="Dienstags"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.tue"
                          @click="GetDefaultSportstaetteIDTue"
                        ></v-switch
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.tue"
                        ><v-menu
                          ref="menu_tue_from"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.tue_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.tue_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.tue_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.tue_from"
                            v-model="viewmodel.form_data.trainings.create_training.data.tue_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_tue_from.save(viewmodel.form_data.trainings.create_training.data.tue_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.tue"
                        ><v-menu
                          ref="menu_tue_to"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.tue_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.tue_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.tue_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.tue_to"
                            v-model="viewmodel.form_data.trainings.create_training.data.tue_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_tue_to.save(viewmodel.form_data.trainings.create_training.data.tue_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.tue"
                      >
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.create_training.data.sportstaette_id_tue"
                        ></v-autocomplete></v-col
                      ><v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.tue"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.create_training.data.hallenteil_tue"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row> </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-switch
                          label="Mittwochs"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.wed"
                          @click="GetDefaultSportstaetteIDWed"
                        ></v-switch
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.wed"
                        ><v-menu
                          ref="menu_wed_from"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.wed_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.wed_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.wed_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.wed_from"
                            v-model="viewmodel.form_data.trainings.create_training.data.wed_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_wed_from.save(viewmodel.form_data.trainings.create_training.data.wed_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.wed"
                        ><v-menu
                          ref="menu_wed_to"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.wed_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.wed_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.wed_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.wed_to"
                            v-model="viewmodel.form_data.trainings.create_training.data.wed_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_wed_to.save(viewmodel.form_data.trainings.create_training.data.wed_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.wed"
                      >
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.create_training.data.sportstaette_id_wed"
                        ></v-autocomplete
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.wed"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.create_training.data.hallenteil_wed"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row> </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-switch
                          label="Donnerstags"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.thu"
                          @click="GetDefaultSportstaetteIDThu"
                        ></v-switch
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.thu"
                        ><v-menu
                          ref="menu_thu_from"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.thu_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.thu_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.thu_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.thu_from"
                            v-model="viewmodel.form_data.trainings.create_training.data.thu_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_thu_from.save(viewmodel.form_data.trainings.create_training.data.thu_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.thu"
                        ><v-menu
                          ref="menu_thu_to"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.thu_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.thu_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.thu_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.thu_to"
                            v-model="viewmodel.form_data.trainings.create_training.data.thu_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_thu_to.save(viewmodel.form_data.trainings.create_training.data.thu_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.thu"
                      >
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.create_training.data.sportstaette_id_thu"
                        ></v-autocomplete
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.thu"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.create_training.data.hallenteil_thu"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row> </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-switch
                          label="Freitags"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.fri"
                          @click="GetDefaultSportstaetteIDFri"
                        ></v-switch
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.fri"
                        ><v-menu
                          ref="menu_fri_from"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.fri_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.fri_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.fri_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.fri_from"
                            v-model="viewmodel.form_data.trainings.create_training.data.fri_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_fri_from.save(viewmodel.form_data.trainings.create_training.data.fri_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.fri"
                        ><v-menu
                          ref="menu_fri_to"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.fri_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.fri_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.fri_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.fri_to"
                            v-model="viewmodel.form_data.trainings.create_training.data.fri_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_fri_to.save(viewmodel.form_data.trainings.create_training.data.fri_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.fri"
                      >
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.create_training.data.sportstaette_id_fri"
                        ></v-autocomplete
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.fri"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.create_training.data.hallenteil_fri"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row> </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-switch
                          label="Samstags"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.sat"
                          @click="GetDefaultSportstaetteIDSat"
                        ></v-switch
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sat"
                        ><v-menu
                          ref="menu_sat_from"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.sat_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.sat_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.sat_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.sat_from"
                            v-model="viewmodel.form_data.trainings.create_training.data.sat_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sat_from.save(viewmodel.form_data.trainings.create_training.data.sat_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sat"
                        ><v-menu
                          ref="menu_sat_to"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.sat_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.sat_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.sat_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.sat_to"
                            v-model="viewmodel.form_data.trainings.create_training.data.sat_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sat_to.save(viewmodel.form_data.trainings.create_training.data.sat_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sat"
                      >
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.create_training.data.sportstaette_id_sat"
                        ></v-autocomplete
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sat"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.create_training.data.hallenteil_sat"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row> </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-switch
                          label="Sonntags"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.sun"
                          @click="GetDefaultSportstaetteIDSun"
                        ></v-switch
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sun"
                        ><v-menu
                          ref="menu_sun_from"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.sun_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.sun_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.sun_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.sun_from"
                            v-model="viewmodel.form_data.trainings.create_training.data.sun_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sun_from.save(viewmodel.form_data.trainings.create_training.data.sun_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sun"
                        ><v-menu
                          ref="menu_sun_to"
                          v-model="viewmodel.state.ui.forms.trainings.create_training.menus.sun_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.create_training.data.sun_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.create_training.data.sun_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.create_training.menus.sun_to"
                            v-model="viewmodel.form_data.trainings.create_training.data.sun_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sun_to.save(viewmodel.form_data.trainings.create_training.data.sun_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sun"
                      >
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.create_training.data.sportstaette_id_sun"
                        ></v-autocomplete
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="viewmodel.state.ui.forms.trainings.create_training.sun"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.create_training.data.hallenteil_sun"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row> </v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-textarea
                          clearable
                          solo
                          auto-grow
                          rows="10"
                          label="Trainingsbeschreibung. Inhalte, Schwerpunkte etc."
                          v-model="viewmodel.form_data.trainings.create_training.data.description"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-textarea></v-col
                    ></v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateTraining" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Training anlegen</v-btn
                        ><v-btn color="error" class="ml-4" @click.prevent="$refs.form_create_training.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.trainings.create_training.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.trainings.create_training.alert"
                      >{{ viewmodel.state.ui.forms.trainings.create_training.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Training bearbeiten -->
            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_update_training" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Mannschaft auswählen"
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainings.update_training.data.mannschaft_id"
                          :items="$store.state.data_tables.trainings.data"
                          item-text="mannschaft_name"
                          item-value="mannschaft_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="GetTrainingByID"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Trainer"
                          disabled
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainings.update_training.data.trainer_id"
                          :items="$store.state.data_tables.trainer.data"
                          item-text="trainer_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          label="Trainingsangebot momentan nicht verfügbar (z.B. Trainingsgruppe voll)"
                          v-model="viewmodel.form_data.trainings.update_training.data.tg_voll"
                        ></v-checkbox></v-col
                    ></v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">Montags</v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_mon_from"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.mon_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.mon_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.mon_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.mon_from"
                            v-model="viewmodel.form_data.trainings.update_training.data.mon_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_mon_from.save(viewmodel.form_data.trainings.update_training.data.mon_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_mon_to"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.mon_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.mon_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.mon_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.mon_to"
                            v-model="viewmodel.form_data.trainings.update_training.data.mon_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_mon_to.save(viewmodel.form_data.trainings.update_training.data.mon_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.update_training.data.sportstaette_id_mon"
                        ></v-autocomplete></v-col
                      ><v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.update_training.data.hallenteil_mon"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">Dienstags</v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_tue_from"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.tue_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.tue_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.tue_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.tue_from"
                            v-model="viewmodel.form_data.trainings.update_training.data.tue_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_tue_from.save(viewmodel.form_data.trainings.update_training.data.tue_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_tue_to"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.tue_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.tue_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.tue_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.tue_to"
                            v-model="viewmodel.form_data.trainings.update_training.data.tue_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_tue_to.save(viewmodel.form_data.trainings.update_training.data.tue_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.update_training.data.sportstaette_id_tue"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.update_training.data.hallenteil_tue"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">Mittwochs</v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_wed_from"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.wed_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.wed_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.wed_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.wed_from"
                            v-model="viewmodel.form_data.trainings.update_training.data.wed_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_wed_from.save(viewmodel.form_data.trainings.update_training.data.wed_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_wed_to"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.wed_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.wed_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.wed_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.wed_to"
                            v-model="viewmodel.form_data.trainings.update_training.data.wed_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_wed_to.save(viewmodel.form_data.trainings.update_training.data.wed_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.update_training.data.sportstaette_id_wed"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.update_training.data.hallenteil_thu"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">Donnerstags</v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_thu_from"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.thu_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.thu_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.thu_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.thu_from"
                            v-model="viewmodel.form_data.trainings.update_training.data.thu_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_thu_from.save(viewmodel.form_data.trainings.update_training.data.thu_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_thu_to"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.thu_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.thu_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.thu_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.thu_to"
                            v-model="viewmodel.form_data.trainings.update_training.data.thu_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_thu_to.save(viewmodel.form_data.trainings.update_training.data.thu_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.update_training.data.sportstaette_id_thu"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.update_training.data.hallenteil_thu"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">Freitags</v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_fri_from"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.fri_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.fri_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.fri_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.fri_from"
                            v-model="viewmodel.form_data.trainings.update_training.data.fri_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_fri_from.save(viewmodel.form_data.trainings.update_training.data.fri_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_fri_to"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.fri_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.fri_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.fri_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.fri_to"
                            v-model="viewmodel.form_data.trainings.update_training.data.fri_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_fri_to.save(viewmodel.form_data.trainings.update_training.data.fri_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.update_training.data.sportstaette_id_fri"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.update_training.data.hallenteil_fri"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">Samstags</v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_sat_from"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.sat_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.sat_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.sat_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.sat_from"
                            v-model="viewmodel.form_data.trainings.update_training.data.sat_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sat_from.save(viewmodel.form_data.trainings.update_training.data.sat_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_sat_to"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.sat_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.sat_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.sat_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.sat_to"
                            v-model="viewmodel.form_data.trainings.update_training.data.sat_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sat_to.save(viewmodel.form_data.trainings.update_training.data.sat_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.update_training.data.sportstaette_id_sat"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.update_training.data.hallenteil_sat"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2">Sonntags</v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_sun_from"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.sun_from"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.sun_from"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.sun_from"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.sun_from"
                            v-model="viewmodel.form_data.trainings.update_training.data.sun_from"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sun_from.save(viewmodel.form_data.trainings.update_training.data.sun_from)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" lg="2" xl="2"
                        ><v-menu
                          ref="menu_sun_to"
                          v-model="viewmodel.state.ui.forms.trainings.update_training.menus.sun_to"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.trainings.update_training.data.sun_to"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.trainings.update_training.data.sun_to"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.trainings.update_training.menus.sun_to"
                            v-model="viewmodel.form_data.trainings.update_training.data.sun_to"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_sun_to.save(viewmodel.form_data.trainings.update_training.data.sun_to)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          v-model="viewmodel.form_data.trainings.update_training.data.sportstaette_id_sun"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-autocomplete
                          label="Hallenteil (falls Mehrfachsporthalle oder Sportmöglichkeiten außen)"
                          clearable
                          prepend-icon="mdi-home-outline"
                          :items="viewmodel.state.ui.forms.trainings.hallenteil"
                          v-model="viewmodel.form_data.trainings.update_training.data.hallenteil_sun"
                        >
                        </v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-textarea
                          clearable
                          solo
                          auto-grow
                          rows="10"
                          label="Trainingsbeschreibung. Inhalte, Schwerpunkte etc."
                          v-model="viewmodel.form_data.trainings.update_training.data.description"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-textarea></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateTraining" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Änderungen speichern</v-btn
                        ><v-btn color="error" class="ml-4" @click.prevent="$refs.form_update_training.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12">
                        <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="IsLoading">
                        </v-progress-linear></v-col
                    ></v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.trainings.update_training.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.trainings.update_training.alert"
                      >{{ viewmodel.state.ui.forms.trainings.update_training.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: Training löschen -->
            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_training"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Mannschaft auswählen:"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainings.delete_training.data.mannschaft_id"
                          :items="$store.state.data_tables.trainings.data"
                          item-text="mannschaft_name"
                          item-value="mannschaft_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          clearable
                          label="Zum Bestätigen das Wort LÖSCHEN eintragen"
                          :rules="viewmodel.form_data.rules.delete_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteTraining" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Training löschen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_delete_training.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.trainings.delete_training.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.trainings.delete_training.alert"
                      >{{ viewmodel.state.ui.forms.trainings.delete_training.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Trainings',
  data: () => ({
    IsLoading: false,
    viewmodel: {
      state: {
        ui: {
          forms: {
            trainings: {
              hallenteil: ['Links', 'Mitte', 'Rechts', 'Außensportanlage', 'Zusatzraum', '-'],
              create_training: {
                alert: false,
                alert_type: 'info',
                response: '',
                menus: {
                  mon_from: false,
                  mon_to: false,
                  tue_from: false,
                  wed_from: false,
                  thu_from: false,
                  fri_from: false,
                  sat_from: false,
                  sun_from: false,
                  tue_to: false,
                  wed_to: false,
                  thu_to: false,
                  fri_to: false,
                  sat_to: false,
                  sun_to: false,
                },
                mon: false,
                tue: false,
                wen: false,
                thu: false,
                fri: false,
                sat: false,
                sun: false,
              },
              update_training: {
                alert: false,
                alert_type: 'info',
                response: '',
                menus: {
                  mon_from: false,
                  mon_to: false,
                  tue_from: false,
                  wed_from: false,
                  thu_from: false,
                  fri_from: false,
                  sat_from: false,
                  sun_from: false,
                  tue_to: false,
                  wed_to: false,
                  thu_to: false,
                  fri_to: false,
                  sat_to: false,
                  sun_to: false,
                },
                mon: false,
                tue: false,
                wen: false,
                thu: false,
                fri: false,
                sat: false,
                sun: false,
              },
              delete_training: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            trainings: {
              show: false,
            },
          },
          tabs: {
            trainings: null,
          },
        },
      },
      form_data: {
        rules: {
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        trainings: {
          create_training: {
            data: {
              trainer_id: '',
              mannschaft_id: '',
              description: '',
              sportstaette_id_mon: '',
              sportstaette_id_tue: '',
              sportstaette_id_wed: '',
              sportstaette_id_thu: '',
              sportstaette_id_fri: '',
              sportstaette_id_sat: '',
              sportstaette_id_sun: '',
              hallenteil_mon: '',
              hallenteil_tue: '',
              hallenteil_wed: '',
              hallenteil_thu: '',
              hallenteil_fri: '',
              hallenteil_sat: '',
              hallenteil_sun: '',
              mon_from: null,
              tue_from: null,
              wed_from: null,
              thu_from: null,
              fri_from: null,
              sat_from: null,
              sun_from: null,
              mon_to: null,
              tue_to: null,
              wed_to: null,
              thu_to: null,
              fri_to: null,
              sat_to: null,
              sun_to: null,
            },
          },
          update_training: {
            data: {
              trainer_id: '',
              mannschaft_id: '',
              description: '',
              sportstaette_id_mon: '',
              sportstaette_id_tue: '',
              sportstaette_id_wed: '',
              sportstaette_id_thu: '',
              sportstaette_id_fri: '',
              sportstaette_id_sat: '',
              sportstaette_id_sun: '',
              hallenteil_mon: '',
              hallenteil_tue: '',
              hallenteil_wed: '',
              hallenteil_thu: '',
              hallenteil_fri: '',
              hallenteil_sat: '',
              hallenteil_sun: '',
              mon_from: null,
              tue_from: null,
              wed_from: null,
              thu_from: null,
              fri_from: null,
              sat_from: null,
              sun_from: null,
              mon_to: null,
              tue_to: null,
              wed_to: null,
              thu_to: null,
              fri_to: null,
              sat_to: null,
              sun_to: null,
              tg_voll: null,
            },
          },
          delete_training: {
            data: {
              id: '',
            },
          },
        },
      },
    },
  }),
  computed: {
    IsDisabled() {
      if (this.$store.state.user.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  methods: {
    CreateTraining() {
      if (this.$refs.form_create_training.validate()) {
        const { data } = this.viewmodel.form_data.trainings.create_training;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_training.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.trainings.create_training.alert = true;
            this.viewmodel.state.ui.forms.trainings.create_training.alert_type = 'success';
            this.viewmodel.state.ui.forms.trainings.create_training.response = response.data.message;
            this.$emit('TrainingCreated');
            // this.Training_GetTrainingsFromUser();
            this.$refs.form_create_training.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.trainings.create_training.alert = true;
            this.viewmodel.state.ui.forms.trainings.create_training.response = error.response.data.message;
            this.viewmodel.state.ui.forms.trainings.create_training.alert_type = 'error';
          });
      }
    },
    UpdateTraining() {
      if (this.$refs.form_update_training.validate()) {
        this.IsLoading = true;
        const { data } = this.viewmodel.form_data.trainings.update_training;
        data.mail = this.$store.state.current_verein_data.mail;
        data.inform_on_training_change = this.$store.state.current_verein_data.inform_on_training_change;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_training.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.trainings.update_training.alert = true;
            this.viewmodel.state.ui.forms.trainings.update_training.alert_type = 'success';
            this.viewmodel.state.ui.forms.trainings.update_training.response = response.data.message;
            this.$emit('TrainingUpdated');
            this.$refs.form_update_training.reset();
            this.IsLoading = false;
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.trainings.update_training.alert = true;
            // eslint-disable-next-line operator-linebreak
            this.viewmodel.state.ui.forms.trainings.update_training.response = error.response.data.message;
            this.viewmodel.state.ui.forms.trainings.update_training.alert_type = 'error';
            this.IsLoading = false;
          });
      }
    },
    DeleteTraining() {
      if (this.$refs.form_delete_training.validate()) {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.delete_training.data.mannschaft_id,
        };
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_training.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.trainings.delete_training.alert = true;
            this.viewmodel.state.ui.forms.trainings.delete_training.alert_type = 'success';
            this.viewmodel.state.ui.forms.trainings.delete_training.response = response.data.message;
            this.$emit('TrainingDeleted');
            this.$refs.form_delete_training.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.trainings.delete_training.alert = true;
            this.viewmodel.state.ui.forms.trainings.delete_training.response = error.response.data.message;
            this.viewmodel.state.ui.forms.trainings.delete_training.alert_type = 'error';
          });
      }
    },
    async GetTrainerByID() {
      const data = {
        mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
      };
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/training_get_trainer_by_id.php`, data);
      this.viewmodel.form_data.trainings.create_training.data.trainer_id = response.data.result[0].trainer_id;
    },
    async GetDefaultSportstaetteIDMon() {
      if (!this.viewmodel.state.ui.forms.trainings.create_training.mon) {
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_mon = null;
      } else {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
          data,
        );
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_mon =
          response.data.result[0].default_sportstaette_id;
      }
    },
    async GetDefaultSportstaetteIDTue() {
      if (!this.viewmodel.state.ui.forms.trainings.create_training.tue) {
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_tue = null;
      } else {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
          data,
        );
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_tue =
          response.data.result[0].default_sportstaette_id;
      }
    },
    async GetDefaultSportstaetteIDWed() {
      if (!this.viewmodel.state.ui.forms.trainings.create_training.wed) {
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_wed = null;
      } else {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
          data,
        );
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_wed =
          response.data.result[0].default_sportstaette_id;
      }
    },
    async GetDefaultSportstaetteIDThu() {
      if (!this.viewmodel.state.ui.forms.trainings.create_training.thu) {
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_thun = null;
      } else {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
          data,
        );
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_thu =
          response.data.result[0].default_sportstaette_id;
      }
    },
    async GetDefaultSportstaetteIDFri() {
      if (!this.viewmodel.state.ui.forms.trainings.create_training.fri) {
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_fri = null;
      } else {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
          data,
        );
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_fri =
          response.data.result[0].default_sportstaette_id;
      }
    },
    async GetDefaultSportstaetteIDSat() {
      if (!this.viewmodel.state.ui.forms.trainings.create_training.sat) {
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_sat = null;
      } else {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
          data,
        );
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_sat =
          response.data.result[0].default_sportstaette_id;
      }
    },
    async GetDefaultSportstaetteIDSun() {
      if (!this.viewmodel.state.ui.forms.trainings.create_training.sun) {
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_sun = null;
      } else {
        const data = {
          mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
          data,
        );
        this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_sun =
          response.data.result[0].default_sportstaette_id;
      }
    },
    async GetDefaultSportstaetteID() {
      const data = {
        mannschaft_id: this.viewmodel.form_data.trainings.create_training.data.mannschaft_id,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/training_get_default_sportstaette_by_id.php`,
        data,
      );
      this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_mon =
        response.data.result[0].default_sportstaette_id;
      this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_tue =
        response.data.result[0].default_sportstaette_id;
      this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_wed =
        response.data.result[0].default_sportstaette_id;
      this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_thu =
        response.data.result[0].default_sportstaette_id;
      this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_fri =
        response.data.result[0].default_sportstaette_id;
      this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_sat =
        response.data.result[0].default_sportstaette_id;
      this.viewmodel.form_data.trainings.create_training.data.sportstaette_id_sun =
        response.data.result[0].default_sportstaette_id;
    },
    async GetTrainingByID() {
      const data = {
        mannschaft_id: this.viewmodel.form_data.trainings.update_training.data.mannschaft_id,
      };
      if (data.mannschaft_id) {
        const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/training_get_training_by_id.php`, data);
        this.viewmodel.form_data.trainings.update_training.data = response.data.result[0];
        this.viewmodel.form_data.trainings.update_training.data.tg_voll = this.ConvertNumberToBoolean(
          this.viewmodel.form_data.trainings.update_training.data.tg_voll,
        );
      }
    },
    ConvertNumberToBoolean(number) {
      if (Number(number) === 1) {
        return true;
      }
      return false;
    },
  },
};
</script>
