<template>
  <div id="MannschaftenPanel">
    <v-toolbar dark color="primary" class="mb-4 mt-2">
      <v-toolbar-title>Trainingsgruppen/Mannschaften</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        text
        @click="viewmodel.state.ui.panels.mannschaften.show = !viewmodel.state.ui.panels.mannschaften.show"
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.mannschaften.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.mannschaften.show">
      <v-card flat
        ><v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="$store.state.data_tables.mannschaften.search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="$store.state.data_tables.mannschaften.headers"
                  :items="$store.state.data_tables.mannschaften.data"
                  :search="$store.state.data_tables.mannschaften.search"
                  sort-by="mannschaft_name"
                >
                </v-data-table> </v-card
            ></v-col>
          </v-row> </v-card-text
      ></v-card>

      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.mannschaften"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Trainingsgruppe/Mannschaft anlegen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-pencil-outline</v-icon>
              Trainingsgruppe/Mannschaft bearbeiten
            </v-tab>
            <v-tab href="#tab-3">
              <v-icon left>mdi-delete-outline</v-icon>
              Trainingsgruppe/Mannschaft löschen
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="viewmodel.state.ui.tabs.mannschaften" touchless>
            <!-- ---------------------------------- Erster Tab: Mannschaft anlegen -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_mannschaft" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Sportart"
                          clearable
                          prepend-icon="mdi-playlist-check"
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.sportart_id"
                          :items="$store.state.data_tables.sportarten.data"
                          item-text="sportart_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Name der Trainingsgruppe/Mannschaft"
                          clearable
                          counter:45
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.mannschaft_name"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Trainer/in"
                          clearable
                          prepend-icon="mdi-account-outline"
                          :items="$store.state.data_tables.trainer.data"
                          item-text="trainer_name"
                          item-value="id"
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.trainer_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="männlich/weiblich/mixed"
                          clearable
                          prepend-icon="mdi-gender-male-female"
                          :items="viewmodel.state.ui.forms.mannschaften.create_mannschaft.gender"
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.geschlecht"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.is_jugendsport"
                          label="Jugendsport"
                          @change="SetIsMarlefitToFalseCreate"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.is_marlefit"
                          label="Teilnahme an MARLeFIT"
                          :disabled="!viewmodel.form_data.mannschaften.create_mannschaft.data.is_jugendsport"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.is_leistungssport"
                          label="Leistungssport"
                          :disabled="viewmodel.form_data.mannschaften.create_mannschaft.data.is_hobby"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.is_hobby"
                          label="Breitensport"
                          :disabled="viewmodel.form_data.mannschaften.create_mannschaft.data.is_leistungssport"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Alter von"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          clearable
                          :items="AgeGenerator_Create"
                          prepend-icon="mdi-calendar-outline"
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.alter_von"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Alter bis"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          clearable
                          :items="AgeGenerator_Create"
                          prepend-icon="mdi-calendar-outline"
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.alter_bis"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Standardsportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          v-model="viewmodel.form_data.mannschaften.create_mannschaft.data.default_sportstaette_id"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateMannschaft" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Mannschaft/TG anlegen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_create_mannschaft.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.mannschaften.create_mannschaft.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.mannschaften.create_mannschaft.alert"
                      >{{ viewmodel.state.ui.forms.mannschaften.create_mannschaft.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Mannschaft bearbeiten -->
            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_update_mannschaft" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Mannschaft/Trainingsgruppe auswählen:"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.id"
                          :items="$store.state.data_tables.mannschaften.data"
                          item-text="mannschaft_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="GetMannschaftByID"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Name der Mannschaft/TG:"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.mannschaft_name"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Sportart"
                          clearable
                          prepend-icon="mdi-playlist-check"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.sportart_id"
                          :items="$store.state.data_tables.sportarten.data"
                          item-text="sportart_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Trainer/in"
                          clearable
                          prepend-icon="mdi-account-outline"
                          :items="$store.state.data_tables.trainer.data"
                          item-text="trainer_name"
                          item-value="id"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.trainer_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="männlich/weiblich/mixed"
                          clearable
                          prepend-icon="mdi-gender-male-female"
                          :items="viewmodel.state.ui.forms.mannschaften.update_mannschaft.gender"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.geschlecht"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.is_jugendsport"
                          label="Jugendsport"
                          @change="SetIsMarlefitToFalseUpdate"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.is_marlefit"
                          label="Teilnahme an MARLeFIT"
                          :disabled="!viewmodel.form_data.mannschaften.update_mannschaft.data.is_jugendsport"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.is_leistungssport"
                          label="Leistungssport"
                          :disabled="viewmodel.form_data.mannschaften.update_mannschaft.data.is_hobby"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.is_hobby"
                          label="Breitensport"
                          :disabled="viewmodel.form_data.mannschaften.update_mannschaft.data.is_leistungssport"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Alter von"
                          :items="AgeGenerator_Update"
                          clearable
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          prepend-icon="mdi-calendar-outline"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.alter_von"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Alter bis"
                          clearable
                          :items="AgeGenerator_Update"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          prepend-icon="mdi-calendar-outline"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.alter_bis"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Standardsportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          v-model="viewmodel.form_data.mannschaften.update_mannschaft.data.default_sportstaette_id"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateMannschaft" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Mannschaft aktualisieren</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_update_mannschaft.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.mannschaften.update_mannschaft.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.mannschaften.update_mannschaft.alert"
                      >{{ viewmodel.state.ui.forms.mannschaften.update_mannschaft.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: Mannschaft löschen -->
            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_mannschaft"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Mannschaft auswählen:"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.mannschaften.delete_mannschaft.data.id"
                          :items="$store.state.data_tables.mannschaften.data"
                          item-text="mannschaft_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          clearable
                          label="Zum Bestätigen das Wort LÖSCHEN eintragen"
                          :rules="viewmodel.form_data.rules.delete_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteMannschaft" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Mannschaft löschen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_delete_mannschaft.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.mannschaften.delete_mannschaft.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.mannschaften.delete_mannschaft.alert"
                      >{{ viewmodel.state.ui.forms.mannschaften.delete_mannschaft.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Mannschaften',
  data: () => ({
    viewmodel: {
      state: {
        ui: {
          forms: {
            mannschaften: {
              create_mannschaft: {
                alert: false,
                alert_type: 'info',
                response: '',
                gender: [
                  { text: 'männlich', value: 'm' },
                  { text: 'weiblich', value: 'w' },
                  { text: 'mixed', value: 'b' },
                ],
              },
              update_mannschaft: {
                alert: false,
                alert_type: 'info',
                response: '',
                gender: [
                  { text: 'männlich', value: 'm' },
                  { text: 'weiblich', value: 'w' },
                  { text: 'mixed', value: 'b' },
                ],
              },
              delete_mannschaft: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            mannschaften: {
              show: false,
            },
          },
          tabs: {
            mannschaften: null,
          },
        },
      },
      form_data: {
        rules: {
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        mannschaften: {
          create_mannschaft: {
            data: {
              mannschaft_name: '',
              trainer_id: '',
              geschlecht: '',
              is_hobby: false,
              is_leistungssport: false,
              is_jugendsport: false,
              is_marlefit: false,
              alter_von: null,
              alter_bis: null,
              default_sportstaette_id: '',
              sportart_id: '',
            },
          },
          update_mannschaft: {
            data: {
              id: '',
              mannschaft_name: '',
              trainer_id: '',
              geschlecht: '',
              is_hobby: false,
              is_leistungssport: false,
              is_jugendsport: false,
              is_marlefit: false,
              alter_von: null,
              alter_bis: null,
              default_sportstaette_id: '',
              spoartart_id: '',
            },
          },
          delete_mannschaft: {
            data: {
              id: '',
            },
          },
        },
      },
    },
  }),
  computed: {
    AgeGenerator_Create() {
      if (this.viewmodel.form_data.mannschaften.create_mannschaft.data.is_jugendsport) {
        return Array.from({ length: 19 }, (x, i) => i);
      }
      return Array.from({ length: 101 }, (x, i) => i);
    },
    AgeGenerator_Update() {
      if (this.viewmodel.form_data.mannschaften.update_mannschaft.data.is_jugendsport) {
        return Array.from({ length: 19 }, (x, i) => i);
      }
      return Array.from({ length: 101 }, (x, i) => i);
    },
    IsDisabled() {
      if (this.$store.state.user.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  methods: {
    CreateMannschaft() {
      if (this.$refs.form_create_mannschaft.validate()) {
        const { data } = this.viewmodel.form_data.mannschaften.create_mannschaft;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_mannschaft.php`, {
            data,
            verein_id: this.$store.state.current_verein.verein_id,
            abteilung_id: this.$store.state.current_verein.abteilung_id,
          })
          .then((response) => {
            this.viewmodel.state.ui.forms.mannschaften.create_mannschaft.alert = true;
            this.viewmodel.state.ui.forms.mannschaften.create_mannschaft.alert_type = 'success';
            this.viewmodel.state.ui.forms.mannschaften.create_mannschaft.response = response.data.message;
            this.$emit('MannschaftCreated');
            // this.Mannschaft_GetMannschaftenFromUser();
            this.$refs.form_create_mannschaft.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.mannschaften.create_mannschaft.alert = true;
            this.viewmodel.state.ui.forms.mannschaften.create_mannschaft.response = error.response.data.message;
            this.viewmodel.state.ui.forms.mannschaften.create_mannschaft.alert_type = 'error';
          });
      }
    },
    UpdateMannschaft() {
      if (this.$refs.form_update_mannschaft.validate()) {
        const { data } = this.viewmodel.form_data.mannschaften.update_mannschaft;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_mannschaft.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.mannschaften.update_mannschaft.alert = true;
            this.viewmodel.state.ui.forms.mannschaften.update_mannschaft.alert_type = 'success';
            this.viewmodel.state.ui.forms.mannschaften.update_mannschaft.response = response.data.message;
            this.$emit('MannschaftUpdated');
            this.$refs.form_update_mannschaft.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.mannschaften.update_mannschaft.alert = true;
            this.viewmodel.state.ui.forms.mannschaften.update_mannschaft.response = error.response.data.message;
            this.viewmodel.state.ui.forms.mannschaften.update_mannschaft.alert_type = 'error';
          });
      }
    },
    DeleteMannschaft() {
      if (this.$refs.form_delete_mannschaft.validate()) {
        const data = {
          id: this.viewmodel.form_data.mannschaften.delete_mannschaft.data.id,
        };
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_mannschaft.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.mannschaften.delete_mannschaft.alert = true;
            this.viewmodel.state.ui.forms.mannschaften.delete_mannschaft.alert_type = 'success';
            this.viewmodel.state.ui.forms.mannschaften.delete_mannschaft.response = response.data.message;
            this.$emit('MannschaftDeleted');
            this.$refs.form_delete_mannschaft.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.mannschaften.delete_mannschaft.alert = true;
            this.viewmodel.state.ui.forms.mannschaften.delete_mannschaft.response = error.response.data.message;
            this.viewmodel.state.ui.forms.mannschaften.delete_mannschaft.alert_type = 'error';
          });
      }
    },
    async GetMannschaftByID() {
      const data = {
        id: this.viewmodel.form_data.mannschaften.update_mannschaft.data.id,
      };
      if (data.id) {
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/mannschaft_get_mannschaft_by_id.php`,
          data,
        );
        this.viewmodel.form_data.mannschaften.update_mannschaft.data = response.data.result[0];
        this.viewmodel.form_data.mannschaften.update_mannschaft.data.is_jugendsport = this.ConvertNumberToBoolean(
          response.data.result[0].is_jugendsport,
        );
        this.viewmodel.form_data.mannschaften.update_mannschaft.data.is_hobby = this.ConvertNumberToBoolean(
          response.data.result[0].is_hobby,
        );
        this.viewmodel.form_data.mannschaften.update_mannschaft.data.is_leistungssport = this.ConvertNumberToBoolean(
          response.data.result[0].is_leistungssport,
        );
        this.viewmodel.form_data.mannschaften.update_mannschaft.data.is_marlefit = this.ConvertNumberToBoolean(
          response.data.result[0].is_marlefit,
        );
        this.viewmodel.form_data.mannschaften.update_mannschaft.data.alter_von = Number(
          response.data.result[0].alter_von,
        );
        this.viewmodel.form_data.mannschaften.update_mannschaft.data.alter_bis = Number(
          response.data.result[0].alter_bis,
        );
      }
    },
    ConvertNumberToBoolean(number) {
      if (Number(number) === 1) {
        return true;
      }
      return false;
    },
    SetIsMarlefitToFalseUpdate() {
      if (this.viewmodel.form_data.mannschaften.update_mannschaft.data.is_jugendsport === false) {
        this.viewmodel.form_data.mannschaften.update_mannschaft.data.is_marlefit = false;
      }
    },
    SetIsMarlefitToFalseCreate() {
      if (this.viewmodel.form_data.mannschaften.create_mannschaft.data.is_jugendsport === false) {
        this.viewmodel.form_data.mannschaften.create_mannschaft.data.is_marlefit = false;
      }
    },
  },
};
</script>
