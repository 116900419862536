<template>
  <v-container class="center-screen">
    <v-card width="100%">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title class="secondary--text">Passwort zurücksetzen</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="state.forms.pwd_reset.is_valid" lazy-validation>
          <v-text-field
            v-model="viewmodel.form_data.pwd_reset.verein_id"
            :rules="viewmodel.form_data.pwd_reset.rules.verein_id"
            label="VereinsID oder Abteilung@VereinsID"
            v-on:keyup.enter="reset_pw"
          ></v-text-field>

          <v-btn color="primary" class="mr-4" @click.prevent="reset_pw">
            OK
          </v-btn>

          <v-btn color="error" class="mr-4" @click.prevent="$router.push({ name: 'login' })">
            Abbrechen
          </v-btn>
        </v-form>
        <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="$store.state.is_loading">
        </v-progress-linear>
        <v-alert
          class="mt-4"
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="state.forms.pwd_reset.alert_type"
          elevation="2"
          v-model="state.forms.pwd_reset.alert"
          >{{ state.forms.pwd_reset.response }}</v-alert
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
const axios = require('axios');

export default {
  data: () => ({
    state: {
      forms: {
        pwd_reset: {
          is_valid: false,
          show_password: false,
          alert_type: 'info',
          alert: false,
          response: '',
        },
      },
    },
    viewmodel: {
      form_data: {
        pwd_reset: {
          verein_id: '',
          rules: {
            verein_id: [(v) => !!v || 'Pflichtfeld'],
          },
        },
      },
    },
  }),

  methods: {
    reset_pw() {
      if (this.$refs.form.validate()) {
        this.$store.state.is_loading = true;
        const data = { verein_id: this.viewmodel.form_data.pwd_reset.verein_id };
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/reset_password.php`, data)
          .then((response) => {
            this.state.forms.pwd_reset.alert = true;
            this.state.forms.pwd_reset.alert_type = 'success';
            this.state.forms.pwd_reset.response = response.data.message;
            this.$emit('PasswortReset');
            this.$store.state.is_loading = false;
            setTimeout(() => {
              this.$router.push({ name: 'login' });
            }, 10000);
          })
          .catch((error) => {
            this.state.forms.pwd_reset.alert = true;
            this.state.forms.pwd_reset.response = error.response.data.message;
            this.state.forms.pwd_reset.alert_type = 'error';
          });
      }
    },
  },
};
</script>

<style scoped>
.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
</style>
