<template>
  <div id="Veranstaltungen_Panel">
    <v-toolbar dark color="primary" class="mb-4 mt-2">
      <v-toolbar-title>Veranstaltungen:</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        text
        @click="viewmodel.state.ui.panels.veranstaltungen.show = !viewmodel.state.ui.panels.veranstaltungen.show"
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.veranstaltungen.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.veranstaltungen.show">
      <v-card flat
        ><v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="$store.state.data_tables.veranstaltungen.search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="$store.state.data_tables.veranstaltungen.headers"
                  :items="$store.state.data_tables.veranstaltungen.data"
                  :search="$store.state.data_tables.veranstaltungen.search"
                  sort-by="event_date"
                >
                  <template v-slot:item.img_url="{ item }">
                    <a v-if="item.img_url" :href="item.img_url" target="blank">Vorschau/Flyer</a>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text></v-card
      >
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.veranstaltungen"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Veranstaltung anlegen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-pencil-outline</v-icon>
              Veranstaltung bearbeiten
            </v-tab>
            <v-tab href="#tab-3">
              <v-icon left>mdi-delete-outline</v-icon>
              Veranstaltung löschen
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="viewmodel.state.ui.tabs.veranstaltungen" touchless>
            <!-- ---------------------------------- Erster Tab: Veranstaltung anlegen -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_veranstaltung" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.sportstaette_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Titel der Veranstaltung"
                          clearable
                          counter:45
                          prepend-icon="mdi-spellcheck"
                          v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.title"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-textarea
                          label="Beschreibung der Veranstaltung"
                          clearable
                          auto-grow
                          rows="10"
                          solo
                          prepend-icon="mdi-spellcheck"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.description"
                        ></v-textarea
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-date-picker
                          v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.event_date"
                          color="tertiary"
                          header-color="primary"
                          full-width
                        ></v-date-picker> </v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-menu
                          ref="menu_time_start"
                          v-model="viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.menus.time_start"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_start"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_start"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="viewmodel.form_data.rules.autocomplete_rules"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.menus.time_start"
                            v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_start"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_time_start.save(
                                viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_start,
                              )
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-menu
                          ref="menu_time_end"
                          v-model="viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.menus.time_end"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_end"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_end"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="viewmodel.form_data.rules.autocomplete_rules"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.menus.time_end"
                            v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_end"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_time_end.save(
                                viewmodel.form_data.veranstaltungen.create_veranstaltung.data.time_end,
                              )
                            "
                          ></v-time-picker>
                        </v-menu> </v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-file-input
                          label="Bild/Flyer etc. (optional)"
                          dense
                          truncate-length="15"
                          accept="image/*"
                          v-model="viewmodel.form_data.veranstaltungen.create_veranstaltung.data.img"
                        ></v-file-input></v-col
                    ></v-row>
                    <v-row v-if="$store.state.user.is_verein"
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><p v-if="AbteilungIsEmpty">
                          Hinweis: Veranstaltung wird auf Vereinsebene angelegt. Um eine Veranstaltung auf
                          Abteilungsebene anzulegen, ganz oben bei den Accountinformationen eine Abteilung auswählen.
                        </p></v-col
                      ></v-row
                    >
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateVeranstaltung" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Veranstaltung anlegen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_create_veranstaltung.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert"
                      >{{ viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Veranstaltung bearbeiten -->
            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_update_veranstaltung" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Veranstaltung auswählen:"
                          clearable
                          prepend-icon="mdi-spellcheck"
                          v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.id"
                          :items="$store.state.data_tables.veranstaltungen.data"
                          item-text="title"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="GetVeranstaltungByID"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Sportstätte"
                          clearable
                          prepend-icon="mdi-home-outline"
                          v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.sportstaette_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          :items="$store.state.data_tables.sportstaetten.data"
                          item-text="sportstaette_name"
                          item-value="id"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Titel der Veranstaltung"
                          clearable
                          prepend-icon="mdi-spellcheck"
                          v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.title"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-textarea
                          label="Beschreibung der Veranstaltung"
                          clearable
                          solo
                          auto-grow
                          rows="10"
                          prepend-icon="mdi-spellcheck"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.description"
                        ></v-textarea
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-date-picker
                          v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.event_date"
                          color="tertiary"
                          header-color="primary"
                          full-width
                        ></v-date-picker> </v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-menu
                          ref="menu_time_start_update"
                          v-model="viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.menus.time_start"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_start"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_start"
                              label="von:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="viewmodel.form_data.rules.autocomplete_rules"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.menus.time_start"
                            v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_start"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_time_start_update.save(
                                viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_start,
                              )
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                        ><v-menu
                          ref="menu_time_end_update"
                          v-model="viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.menus.time_end"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_end"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_end"
                              label="bis:"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="viewmodel.form_data.rules.autocomplete_rules"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.menus.time_end"
                            v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_end"
                            full-width
                            scrollable
                            format="24hr"
                            @click:minute="
                              $refs.menu_time_end_update.save(
                                viewmodel.form_data.veranstaltungen.update_veranstaltung.data.time_end,
                              )
                            "
                          ></v-time-picker>
                        </v-menu> </v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-file-input
                          label="Bild/Flyer etc. (optional)"
                          dense
                          truncate-length="15"
                          accept="image/*"
                          v-model="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.img"
                        ></v-file-input></v-col
                    ></v-row>
                    <v-row v-if="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.img_url !== '-1'"
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-img
                          max-width="100%"
                          :src="viewmodel.form_data.veranstaltungen.update_veranstaltung.data.img_url"
                        ></v-img></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateVeranstaltung" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Veranstaltung aktualisieren</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_update_veranstaltung.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.alert"
                      >{{ viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: Veranstaltung löschen -->
            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_veranstaltung"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Veranstaltung auswählen:"
                          clearable
                          prepend-icon="mdi-spellcheck"
                          v-model="viewmodel.form_data.veranstaltungen.delete_veranstaltung.data.id"
                          :items="$store.state.data_tables.veranstaltungen.data"
                          item-text="title"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          clearable
                          label="Zum Bestätigen das Wort LÖSCHEN eintragen"
                          :rules="viewmodel.form_data.rules.delete_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteVeranstaltung" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Veranstaltung löschen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_delete_veranstaltung.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.alert"
                      >{{ viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Veranstaltungen',
  data: () => ({
    valid: true,
    viewmodel: {
      state: {
        ui: {
          forms: {
            veranstaltungen: {
              create_veranstaltung: {
                alert: false,
                alert_type: 'info',
                response: '',
                menus: {
                  time_start: false,
                  time_end: false,
                },
              },
              update_veranstaltung: {
                alert: false,
                alert_type: 'info',
                response: '',
                menus: {
                  time_start: false,
                  time_end: false,
                },
              },
              delete_veranstaltung: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            veranstaltungen: {
              show: false,
            },
          },
          tabs: {
            veranstaltungen: null,
          },
        },
      },
      form_data: {
        rules: {
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        veranstaltungen: {
          create_veranstaltung: {
            data: {
              title: '',
              description: '',
              img: [],
              sportstaette_id: '',
              event_date: '',
              time_start: '',
              time_end: '',
            },
          },
          update_veranstaltung: {
            data: {
              id: '',
              title: '',
              description: '',
              sportstaette_id: '',
              img: [],
              img_url: '',
              event_date: '',
              time_start: '',
              time_end: '',
            },
          },
          delete_veranstaltung: {
            data: {
              id: '',
            },
          },
        },
      },
    },
  }),
  computed: {
    AbteilungIsEmpty() {
      if (
        this.$store.state.current_verein.abteilung_id === undefined ||
        this.$store.state.current_verein.abteilung_id === null
      ) {
        return true;
      }
      return false;
    },
    IsDisabled() {
      if (this.$store.state.user.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  methods: {
    CreateVeranstaltung() {
      if (this.viewmodel.form_data.veranstaltungen.create_veranstaltung.data.event_date === '') {
        this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert = true;
        this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.response =
          'Kein Datum für die Veranstaltung ausgewählt';
        this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert_type = 'error';
      } else if (this.$refs.form_create_veranstaltung.validate()) {
        const { data } = this.viewmodel.form_data.veranstaltungen.create_veranstaltung;
        data.verein_id = this.$store.state.current_verein.verein_id;
        data.abteilung_id = this.$store.state.current_verein.abteilung_id;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.veranstaltungen.create_veranstaltung.data.img);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_veranstaltung.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert = true;
            this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert_type = 'success';
            this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.response = response.data.message;
            this.$emit('VeranstaltungCreated');
            this.$refs.form_create_veranstaltung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert = true;
            this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.response = error.response.data.message;
            this.viewmodel.state.ui.forms.veranstaltungen.create_veranstaltung.alert_type = 'error';
          });
      }
    },
    UpdateVeranstaltung() {
      if (this.$refs.form_update_veranstaltung.validate()) {
        const { data } = this.viewmodel.form_data.veranstaltungen.update_veranstaltung;
        data.verein_id = this.$store.state.current_verein.verein_id;
        data.abteilung_id = this.$store.state.current_verein.abteilung_id;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.veranstaltungen.update_veranstaltung.data.img);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_veranstaltung.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.alert = true;
            this.viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.alert_type = 'success';
            this.viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.response = response.data.message;
            this.viewmodel.form_data.veranstaltungen.update_veranstaltung.data.img_url = '-1';
            this.$emit('VeranstaltungUpdated');
            this.$refs.form_update_veranstaltung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.alert = true;
            this.viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.response = error.response.data.message;
            this.viewmodel.state.ui.forms.veranstaltungen.update_veranstaltung.alert_type = 'error';
          });
      }
    },
    DeleteVeranstaltung() {
      if (this.$refs.form_delete_veranstaltung.validate()) {
        const data = {
          id: this.viewmodel.form_data.veranstaltungen.delete_veranstaltung.data.id,
        };
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_veranstaltung.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.alert = true;
            this.viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.alert_type = 'success';
            this.viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.response = response.data.message;
            this.$emit('VeranstaltungDeleted');
            this.$refs.form_delete_veranstaltung.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.alert = true;
            this.viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.response = error.response.data.message;
            this.viewmodel.state.ui.forms.veranstaltungen.delete_veranstaltung.alert_type = 'error';
          });
      }
    },
    async GetVeranstaltungByID() {
      const data = {
        id: this.viewmodel.form_data.veranstaltungen.update_veranstaltung.data.id,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/veranstaltung_get_veranstaltung_by_id.php`,
        data,
      );
      this.viewmodel.form_data.veranstaltungen.update_veranstaltung.data = response.data.result[0];
    },
  },
};
</script>
