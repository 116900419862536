/* eslint-disable func-names */
<template>
  <div class="mt-2 ml-2 mr-2">
    <v-card width="100%">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title class="secondary--text">Administration</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="mr-4" text @click.prevent="Store_Logout">Logout</v-btn>
      </v-toolbar>
      <v-card-text>
        <div id="CurrentUserPanel">
          <v-toolbar flat color="transparent">
            <v-toolbar-title>Accountinformationen</v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4" xl="4">
              <v-text-field
                disabled
                outlined
                v-model="$store.state.current_verein.verein_id"
                label="VereinsID"
                prepend-icon="mdi-badge-account-outline"
              >
              </v-text-field> </v-col
          ></v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4" xl="4">
              <v-autocomplete
                outlined
                :disabled="!$store.state.user.is_admin && !$store.state.current_verein_data.verein_id === '1000001'"
                v-model="$store.state.current_verein.verein_id"
                label="Verein"
                auto-select-first
                prepend-icon="mdi-account-outline"
                :items="$store.state.data_tables.vereine.data"
                item-text="verein_name"
                item-value="verein_id"
                @change="Store_SetCurrentVerein"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4" xl="4">
              <v-autocomplete
                outlined
                :disabled="!$store.state.user.is_admin && !$store.state.user.is_verein"
                v-model="$store.state.current_verein.abteilung_id"
                label="Abteilung"
                prepend-icon="mdi-account-outline"
                :items="$store.state.data_tables.abteilungen.data"
                item-text="abteilung_name"
                item-value="id"
                @change="Store_SetCurrentAbteilung"
                clearable
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>

        <v-divider v-if="$store.state.user.is_admim"></v-divider>

        <Funktionaere
          v-if="
            ($store.state.user.is_admin && $store.state.current_verein.verein_id === '1000000') ||
              $store.state.current_verein.verein_id === '1000001'
          "
          @FunktionaerCreated="GetAllFunktionaere"
          @FunktionaerUpdated="GetAllFunktionaere"
          @FunktionaerDeleted="GetAllFunktionaere"
        ></Funktionaere>

        <Vereinuebersicht
          v-if="
            ($store.state.user.is_admin && $store.state.current_verein.verein_id === '1000000') ||
              $store.state.current_verein.verein_id === '1000001'
          "
          @VereinCreated="RefreshAllData"
          @VereinIDUpdated="RefreshAllData"
          @VereinDeleted="RefreshAllData"
        ></Vereinuebersicht>

        <Sportstaettenuebersicht
          v-if="
            ($store.state.user.is_admin && $store.state.current_verein.verein_id === '1000000') ||
              $store.state.current_verein.verein_id === '1000001'
          "
          @SportstaetteCreated="GetAllSportstaetten"
          @SportstaetteUpdated="GetAllSportstaetten"
          @SportstaetteDeleted="GetAllSportstaetten"
        ></Sportstaettenuebersicht>

        <Sportartenuebersicht
          v-if="
            ($store.state.user.is_admin && $store.state.current_verein.verein_id === '1000000') ||
              $store.state.current_verein.verein_id === '1000001'
          "
          @SportartCreated="GetAllSportarten"
          @SportartUpdated="GetAllSportarten"
          @SportartDeleted="GetAllSportarten"
        ></Sportartenuebersicht>

        <FAQ></FAQ>

        <Verein
          v-if="$store.state.data_tables.vereine.data.length > 0"
          @verein-updated="RefreshAllVereinData"
          @AbteilungCreated="GetAbteilungenFromCurrentVerein"
          @AbteilungUpdated="GetAbteilungenFromCurrentVerein"
          @AbteilungDeleted="RefreshAllData"
          @VereinsportartCreated="GetAbteilungenFromCurrentVerein"
          @GemeinnuetzigkeitUploaded="RefreshAllVereinData"
          @LogoUploaded="RefreshAllVereinData"
          @SatzungUploaded="RefreshAllVereinData"
          @HygieneConceptUploaded="RefreshAllVereinData"
        ></Verein>

        <Trainer
          @TrainerCreated="RefreshAllAbteilungData"
          @TrainerUpdated="RefreshAllAbteilungData"
          @TrainerDeleted="RefreshAllAbteilungData"
        >
        </Trainer>

        <Mannschaften
          @MannschaftCreated="RefreshAllAbteilungData"
          @MannschaftUpdated="RefreshAllAbteilungData"
          @MannschaftDeleted="RefreshAllAbteilungData"
        ></Mannschaften>

        <Trainings
          @TrainingCreated="GetTrainingsFromCurrentAbteilung"
          @TrainingUpdated="GetTrainingsFromCurrentAbteilung"
          @TrainingDeleted="GetTrainingsFromCurrentAbteilung"
        ></Trainings>

        <Veranstaltungen
          @VeranstaltungCreated="GetVeranstaltungen"
          @VeranstaltungUpdated="GetVeranstaltungen"
          @VeranstaltungDeleted="GetVeranstaltungen"
        ></Veranstaltungen>

        <v-divider v-if="$store.state.user.is_admim"></v-divider>

        <v-divider v-if="$store.state.user.is_admim"></v-divider>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Funktionaere from '../components/Admin/Vereinsfunktionaere.vue';
import Vereinuebersicht from '../components/Admin/Vereinuebersicht.vue';
import Sportstaettenuebersicht from '../components/Admin/Sportstaettenuebersicht.vue';
import Sportartenuebersicht from '../components/Admin/Sportartenuebersicht.vue';
import Verein from '../components/Admin/Verein.vue';
import Trainer from '../components/Admin/Trainer.vue';
import Mannschaften from '../components/Admin/Mannschaften.vue';
import Trainings from '../components/Admin/Trainings.vue';
import Veranstaltungen from '../components/Admin/Veranstaltungen.vue';
import FAQ from '../components/Admin/FAQ.vue';

const axios = require('axios');

export default {
  name: 'Admin',
  components: {
    Funktionaere,
    Vereinuebersicht,
    Sportstaettenuebersicht,
    Sportartenuebersicht,
    FAQ,
    Verein,
    Trainer,
    Mannschaften,
    Trainings,
    Veranstaltungen,
  },
  data: () => ({}),
  methods: {
    Store_SetCurrentVerein() {
      const current_verein = {
        verein_id: this.$store.state.current_verein.verein_id,
        abteilung_id: null,
      };
      this.$store.dispatch('SetCurrentVerein', current_verein);
      this.GetAbteilungenFromCurrentVerein();
      this.GetVereinFromCurrentVerein();
      this.GetFunktionaereFromCurrentVerein();
      this.RefreshAllAbteilungData();
    },
    Store_SetCurrentAbteilung() {
      const current_abteilung = {
        abteilung_id: this.$store.state.current_verein.abteilung_id,
      };
      this.$store.dispatch('SetCurrentAbteilung', current_abteilung);
      this.RefreshAllAbteilungData();
    },
    Store_Logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => {});
    },
    // wird nach Vereinsupdate aufgerufen. Aktualisiert die Daten in der Vereinsübersicht
    // und im Verein Panel
    RefreshAllVereinData() {
      this.GetAllVereine();
      this.GetAllFunktionaere();
      this.GetVereinFromCurrentVerein();
    },
    RefreshAllAbteilungData() {
      this.GetTrainerFromCurrentAbteilung();
      this.GetMannschaftenFromCurrentAbteilung();
      this.GetTrainingsFromCurrentAbteilung();
      this.GetVeranstaltungen();
    },
    RefreshAllData() {
      this.GetAllVereine();
      this.GetAllFunktionaere();
      this.GetVereinFromCurrentVerein();
      this.GetAbteilungenFromCurrentVerein();
      this.GetTrainerFromCurrentAbteilung();
      this.GetMannschaftenFromCurrentAbteilung();
      this.GetTrainingsFromCurrentAbteilung();
      this.GetVeranstaltungen();
    },
    async GetAllFunktionaere() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/funktionaer_get_all_funktionaere.php`);
      this.$store.state.data_tables.funktionaere.data = response.data.result;
    },
    async GetAllVereine() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_get_all_vereine.php`);
      this.$store.state.data_tables.vereine.data = response.data.result;
    },
    async GetAbteilungenFromCurrentVerein() {
      const { verein_id } = this.$store.state.current_verein;
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_get_abteilungen_from_current_verein.php`,
        {
          verein_id,
        },
      );
      this.$store.state.data_tables.abteilungen.data = response.data.result;
    },
    async GetAllSportstaetten() {
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/sportstaettenuebersicht_get_all_sportstaetten.php`,
      );
      this.$store.state.data_tables.sportstaetten.data = response.data.result;
    },
    async GetAllSportarten() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/sportarten_get_all_sportarten.php`);
      this.$store.state.data_tables.sportarten.data = response.data.result;
    },
    async GetVereinFromCurrentVerein() {
      const data = {
        verein_id: this.$store.state.current_verein.verein_id,
      };
      if (data.verein_id) {
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/verein_get_verein_from_current_verein.php`,
          data,
        );
        this.$store.state.current_verein_data = response.data.result[0];
        if (this.$store.state.current_verein_data.is_fuehrungszeugnis === '1') {
          this.$store.state.current_verein_data.is_fuehrungszeugnis = true;
        } else {
          this.$store.state.current_verein_data.is_fuehrungszeugnis = false;
        }
        if (this.$store.state.current_verein_data.is_marlefit === '1') {
          this.$store.state.current_verein_data.is_marlefit = true;
        } else {
          this.$store.state.current_verein_data.is_marlefit = false;
        }
        if (this.$store.state.current_verein_data.is_active === '1') {
          this.$store.state.current_verein_data.is_active = true;
        } else {
          this.$store.state.current_verein_data.is_active = false;
        }
        if (this.$store.state.current_verein_data.inform_on_training_change === '1') {
          this.$store.state.current_verein_data.inform_on_training_change = true;
        } else {
          this.$store.state.current_verein_data.inform_on_training_change = false;
        }
      }
    },
    async GetFunktionaereFromCurrentVerein() {
      const { verein_id } = this.$store.state.current_verein;
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/verein_get_funktionaere_from_current_verein.php`,
        {
          verein_id,
        },
      );
      this.$store.state.current_verein_funktionaere.data = response.data.result;
    },
    async GetTrainerFromCurrentAbteilung() {
      const data = {
        verein_id: this.$store.state.current_verein.verein_id,
        abteilung_id: this.$store.state.current_verein.abteilung_id,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/trainer_get_trainer_from_current_abteilung.php`,
        data,
      );
      this.$store.state.data_tables.trainer.data = response.data.result;
    },
    async GetMannschaftenFromCurrentAbteilung() {
      const data = {
        verein_id: this.$store.state.current_verein.verein_id,
        abteilung_id: this.$store.state.current_verein.abteilung_id,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/mannschaft_get_mannschaften_from_current_abteilung.php`,
        data,
      );
      this.$store.state.data_tables.mannschaften.data = response.data.result;
    },
    async GetTrainingsFromCurrentAbteilung() {
      const data = {
        verein_id: this.$store.state.current_verein.verein_id,
        abteilung_id: this.$store.state.current_verein.abteilung_id,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/training_get_trainings_from_current_abteilung.php`,
        data,
      );
      this.$store.state.data_tables.trainings.data = response.data.result;
    },
    async GetVeranstaltungen() {
      const data = {
        verein_id: this.$store.state.current_verein.verein_id,
        abteilung_id: this.$store.state.current_verein.abteilung_id,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/veranstaltung_get_veranstaltungen.php`,
        data,
      );
      this.$store.state.data_tables.veranstaltungen.data = response.data.result;
    },
  },
  created() {
    this.GetAllFunktionaere();
    this.GetAllVereine();
    this.GetVereinFromCurrentVerein();
    this.GetAbteilungenFromCurrentVerein();
    this.GetAllSportstaetten();
    this.GetAllSportarten();
    this.GetTrainerFromCurrentAbteilung();
    this.GetMannschaftenFromCurrentAbteilung();
    this.GetTrainingsFromCurrentAbteilung();
    this.GetVeranstaltungen();
  },
  beforeCreate() {
    this.$store.dispatch('validate_token');
  },
};
</script>
