<template>
  <div id="TrainerPanel">
    <v-toolbar dark color="primary" class="mb-4 mt-2">
      <v-toolbar-title>Trainer/innen</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn fab small text @click="viewmodel.state.ui.panels.trainer.show = !viewmodel.state.ui.panels.trainer.show"
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.trainer.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.trainer.show">
      <v-card flat
        ><v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="$store.state.data_tables.trainer.search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="$store.state.data_tables.trainer.headers"
                  :items="$store.state.data_tables.trainer.data"
                  :search="$store.state.data_tables.trainer.search"
                  sort-by="trainer_name"
                >
                </v-data-table> </v-card
            ></v-col>
          </v-row> </v-card-text
      ></v-card>
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.trainer"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Trainer/in anlegen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-pencil-outline</v-icon>
              Trainer/in bearbeiten
            </v-tab>
            <v-tab href="#tab-3">
              <v-icon left>mdi-delete-outline</v-icon>
              Trainer/in löschen
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="viewmodel.state.ui.tabs.trainer" touchless>
            <!-- ---------------------------------- Erster Tab: Trainer anlegen -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_trainer" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Name"
                          clearable
                          counter:45
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainer.create_trainer.data.trainer_name"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="E-Mail Adresse"
                          clearable
                          counter="45"
                          prepend-icon="mdi-email-outline"
                          v-model="viewmodel.form_data.trainer.create_trainer.data.mail"
                          :rules="viewmodel.form_data.rules.mail_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Telefon"
                          clearable
                          dense
                          counter="45"
                          prepend-icon="mdi-phone-outline"
                          v-model="viewmodel.form_data.trainer.create_trainer.data.phone"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          label="Kontaktdaten öffentlich einsehbar"
                          v-model="viewmodel.form_data.trainer.create_trainer.data.is_public"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateTrainer" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Trainer anlegen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_create_trainer.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.trainer.create_trainer.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.trainer.create_trainer.alert"
                      >{{ viewmodel.state.ui.forms.trainer.create_trainer.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Trainer bearbeiten -->
            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_update_trainer" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Trainer/in auswählen:"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainer.update_trainer.data.id"
                          :items="$store.state.data_tables.trainer.data"
                          item-text="trainer_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="GetTrainerByID"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Name"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainer.update_trainer.data.trainer_name"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="E-Mail Adresse"
                          clearable
                          prepend-icon="mdi-email-outline"
                          v-model="viewmodel.form_data.trainer.update_trainer.data.mail"
                          :rules="viewmodel.form_data.rules.mail_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Telefon"
                          clearable
                          prepend-icon="mdi-phone-outline"
                          v-model="viewmodel.form_data.trainer.update_trainer.data.phone"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          label="Kontaktdaten öffentlich einsehbar"
                          v-model="viewmodel.form_data.trainer.update_trainer.data.is_public"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateTrainer" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Trainer aktualisieren</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_update_trainer.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.trainer.update_trainer.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.trainer.update_trainer.alert"
                      >{{ viewmodel.state.ui.forms.trainer.update_trainer.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: Trainer löschen -->
            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_trainer"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          label="Trainer auswählen:"
                          clearable
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.trainer.delete_trainer.data.id"
                          :items="$store.state.data_tables.trainer.data"
                          item-text="trainer_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          clearable
                          label="Zum Bestätigen das Wort LÖSCHEN eintragen"
                          :rules="viewmodel.form_data.rules.delete_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteTrainer" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Trainer löschen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_delete_trainer.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >

                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.trainer.delete_trainer.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.trainer.delete_trainer.alert"
                      >{{ viewmodel.state.ui.forms.trainer.delete_trainer.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Trainer',
  data: () => ({
    viewmodel: {
      state: {
        ui: {
          forms: {
            trainer: {
              create_trainer: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              update_trainer: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              delete_trainer: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            trainer: {
              show: false,
            },
          },
          tabs: {
            trainer: null,
          },
        },
      },
      form_data: {
        rules: {
          mail_rules: [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Keine gültige E-Mail Adresse'],
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        trainer: {
          create_trainer: {
            data: {
              trainer_name: '',
              mail: '',
              phone: '',
              is_public: true,
            },
          },
          update_trainer: {
            data: {
              id: '',
              trainer_name: '',
              mail: '',
              phone: '',
              is_public: '',
            },
          },
          delete_trainer: {
            data: {
              id: '',
            },
          },
        },
      },
    },
  }),
  computed: {
    IsDisabled() {
      if (this.$store.state.user.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  methods: {
    CreateTrainer() {
      if (this.$refs.form_create_trainer.validate()) {
        const { data } = this.viewmodel.form_data.trainer.create_trainer;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_trainer.php`, {
            data,
            verein_id: this.$store.state.current_verein.verein_id,
            abteilung_id: this.$store.state.current_verein.abteilung_id,
          })
          .then((response) => {
            this.viewmodel.state.ui.forms.trainer.create_trainer.alert = true;
            this.viewmodel.state.ui.forms.trainer.create_trainer.alert_type = 'success';
            this.viewmodel.state.ui.forms.trainer.create_trainer.response = response.data.message;
            this.$emit('TrainerCreated');
            this.$refs.form_create_trainer.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.trainer.create_trainer.alert = true;
            this.viewmodel.state.ui.forms.trainer.create_trainer.response = error.response.data.message;
            this.viewmodel.state.ui.forms.trainer.create_trainer.alert_type = 'error';
          });
      }
    },
    UpdateTrainer() {
      if (this.$refs.form_update_trainer.validate()) {
        const { data } = this.viewmodel.form_data.trainer.update_trainer;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_trainer.php`, {
            data,
            verein_id: this.$store.state.current_verein.verein_id,
            abteilung_id: this.$store.state.current_verein.abteilung_id,
          })
          .then((response) => {
            this.viewmodel.state.ui.forms.trainer.update_trainer.alert = true;
            this.viewmodel.state.ui.forms.trainer.update_trainer.alert_type = 'success';
            this.viewmodel.state.ui.forms.trainer.update_trainer.response = response.data.message;
            this.$emit('TrainerUpdated');
            this.$refs.form_update_trainer.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.trainer.update_trainer.alert = true;
            this.viewmodel.state.ui.forms.trainer.update_trainer.response = error.response.data.message;
            this.viewmodel.state.ui.forms.trainer.update_trainer.alert_type = 'error';
          });
      }
    },
    DeleteTrainer() {
      if (this.$refs.form_delete_trainer.validate()) {
        const data = {
          id: this.viewmodel.form_data.trainer.delete_trainer.data.id,
        };
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_trainer.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.trainer.delete_trainer.alert = true;
            this.viewmodel.state.ui.forms.trainer.delete_trainer.alert_type = 'success';
            this.viewmodel.state.ui.forms.trainer.delete_trainer.response = response.data.message;
            this.$emit('TrainerDeleted');
            this.$refs.form_delete_trainer.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.trainer.delete_trainer.alert = true;
            this.viewmodel.state.ui.forms.trainer.delete_trainer.response = this.GetInUseArray(
              error.response.data.message,
              error.response.data.result,
            );
            this.viewmodel.state.ui.forms.trainer.delete_trainer.alert_type = 'error';
          });
      }
    },
    async GetTrainerByID() {
      const data = {
        id: this.viewmodel.form_data.trainer.update_trainer.data.id,
      };
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/trainer_get_trainer_by_id.php`, data);
      this.viewmodel.form_data.trainer.update_trainer.data = response.data.result[0];
      if (this.viewmodel.form_data.trainer.update_trainer.data.is_public === '0') {
        this.viewmodel.form_data.trainer.update_trainer.data.is_public = false;
      } else {
        this.viewmodel.form_data.trainer.update_trainer.data.is_public = true;
      }
    },
    GetInUseArray(response, result) {
      let mannschaft = '';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < result.length; i++) {
        if (result.length - i > 1) {
          mannschaft += `${result[i].mannschaft_name},\n`;
        } else {
          mannschaft += `${result[i].mannschaft_name}.\n`;
        }
      }
      return `${response}\n${mannschaft}`;
    },
  },
};
</script>
