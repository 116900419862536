<template>
  <v-container class="center-screen">
    <v-card width="100%">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title class="secondary--text">Passwort ändern</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="viewmodel.form_data.change_pw.password"
            :rules="viewmodel.form_data.change_pw.rules.password"
            :append-icon="state.forms.change_pw.show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="state.forms.change_pw.show_password ? 'text' : 'password'"
            @click:append="state.forms.change_pw.show_password = !state.forms.change_pw.show_password"
            label="neues Passwort"
            v-on:keyup.enter="change_pw"
          ></v-text-field>
          <v-text-field
            v-model="viewmodel.form_data.change_pw.password_confirm"
            :rules="viewmodel.form_data.change_pw.rules.password_confirm"
            :append-icon="state.forms.change_pw.show_password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
            :type="state.forms.change_pw.show_password_confirm ? 'text' : 'password'"
            @click:append="state.forms.change_pw.show_password_confirm = !state.forms.change_pw.show_password_confirm"
            label="Passwort bestätigen"
            v-on:keyup.enter="change_pw"
          ></v-text-field>

          <v-btn color="primary" class="mr-4" @click.prevent="change_pw">
            OK
          </v-btn>

          <v-btn color="error" class="mr-4" @click.prevent="$router.push({ name: 'home' })">
            Abbrechen
          </v-btn>
        </v-form>
        <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="state.is_loading">
        </v-progress-linear>
        <v-alert
          class="mt-4"
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="state.forms.change_pw.alert_type"
          elevation="2"
          v-model="state.forms.change_pw.alert"
          >{{ state.forms.change_pw.response }}</v-alert
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
const axios = require('axios');

export default {
  data: () => ({
    state: {
      is_loading: false,
      forms: {
        change_pw: {
          is_valid: false,
          show_password: false,
          show_password_confirm: false,
          alert_type: 'info',
          alert: false,
          response: '',
        },
      },
    },
    viewmodel: {
      form_data: {
        change_pw: {
          password: '',
          password_confirm: '',
          rules: {
            password: [
              (v) => !!v || 'Pflichtfeld',
              (v) => (v && v.length > 7) || 'Das Passwort muss mindestens 8 Zeichen enthalten',
            ],
            password_confirm: [
              (v) => !!v || 'Pflichtfeld',
              (v) => (v && v.length > 7) || 'Das Passwort muss mindestens 8 Zeichen enthalten',
            ],
          },
        },
      },
    },
  }),
  computed: {
    IsLoading() {
      return this.state.is_loading;
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.state.forms.change_pw.alert = false;
      this.state.forms.change_pw.alert_type = 'info';
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    change_pw() {
      const data = {
        password: this.viewmodel.form_data.change_pw.password,
        password_confirm: this.viewmodel.form_data.change_pw.password_confirm,
        verein_id: this.$store.state.user.verein_id,
        abteilung_id: this.$store.state.user.abteilung_id,
      };
      if (data.password === data.password_confirm) {
        if (this.$refs.form.validate()) {
          this.state.is_loading = true;
          axios
            .post(`${process.env.VUE_APP_APIBASEURL}/change_password.php`, data)
            .then((response) => {
              this.state.is_loading = false;
              this.state.forms.change_pw.alert = true;
              this.state.forms.change_pw.alert_type = 'success';
              this.state.forms.change_pw.response = response.data.message;
              this.$store.commit('set_pwd_change_required_to_false');
              this.$router.push('/admin');
            })
            .catch((error) => {
              this.state.is_loading = false;
              this.state.forms.change_pw.alert = true;
              this.state.forms.change_pw.response = error.response.data.message;
              this.state.forms.change_pw.alert_type = 'error';
            });
        }
      } else {
        this.state.forms.change_pw.alert = true;
        this.state.forms.change_pw.alert_type = 'error';
        this.state.forms.change_pw.response = 'Passwörter stimmen nicht überein.';
      }
    },
  },
};
</script>

<style scoped>
.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
</style>
