<template
  ><div>
    <v-data-table
      :headers="$store.state.current_verein_funktionaere.headers"
      :items="$store.state.current_verein_funktionaere.data"
      sort-by="funktion_id"
    >
      <template v-slot:top>
        <v-form ref="form_create_or_edit_funktionaer">
          <v-toolbar flat>
            <v-toolbar-title>Vereinsfunktionäre</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="650px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="$store.state.user.is_admin || $store.state.user.is_verein"
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Person hinzufügen
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.anrede"
                          :items="anrede"
                          label="Anrede"
                          :rules="rules.generic_rules"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          clearable
                          v-model="editedItem.title"
                          :items="title"
                          label="Titel"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.vorname"
                          label="Vorname"
                          :rules="rules.generic_rules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="rules.generic_rules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.funktion_id"
                          label="Funktion"
                          :items="$store.state.data_tables.funktionaere.data"
                          item-text="funktion"
                          item-value="id"
                          :rules="rules.autocomplete_rules"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.zusatz_1"
                          label="Bemerkung (z.B. bei Abteilungsleiter die Abteilung)"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.street"
                          label="Anschrift"
                          :rules="rules.generic_rules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="editedItem.plz" label="PLZ" :rules="rules.plz_rules"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.city"
                          label="Stadt"
                          :rules="rules.generic_rules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.mail" label="E-Mail" :rules="rules.mail_rules"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Telefon"
                          :rules="rules.generic_rules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.vereinsbeitritt"
                              label="Vereinsbeitritt (optional, JJJJ-MM-TT)"
                              prepend-icon="mdi-calendar"
                              hint="Achtung, JJJJ-MM-TT"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="editedItem.vereinsbeitritt"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="save_date"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                          ref="menu_geb"
                          v-model="menu_geb"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.geburtstag"
                              label="Geburtstag (optional, JJJJ-MM-TT)"
                              prepend-icon="mdi-calendar"
                              hint="Achtung, JJJJ-MM-TT"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="editedItem.geburtstag"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="save_date_geb"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close">
                    Abbrechen
                  </v-btn>
                  <v-btn color="success" @click="save" :disabled="IsDisabled">
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Löschen bestätigen</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="success" text @click="deleteItemConfirm" :disabled="IsDisabled">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </v-form>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="$store.state.user.is_admin || $store.state.user.is_verein"
        >
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)" v-if="$store.state.user.is_admin || $store.state.user.is_verein">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-alert
      border="top"
      transition="fade-transition"
      dismissible
      colored-border
      :type="viewmodel.state.ui.forms.funktionaere.alert_type"
      elevation="2"
      v-model="viewmodel.state.ui.forms.funktionaere.alert"
      >{{ viewmodel.state.ui.forms.funktionaere.response }}</v-alert
    >
    <v-row
      ><v-col cols="12">
        <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="IsLoading">
        </v-progress-linear></v-col
    ></v-row>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Funktionaere',
  data: () => ({
    menu: false,
    menu_geb: false,
    anrede: ['Herr', 'Frau'],
    title: ['Dr.', 'Prof.', 'Prof. Dr.'],
    viewmodel: {
      state: {
        is_loading: false,
        ui: {
          forms: {
            funktionaere: {
              alert_type: 'info',
              alert: false,
              response: '',
            },
          },
        },
      },
    },
    rules: {
      generic_rules: [
        (v) => !!v || 'Pflichtfeld',
        (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
      ],
      plz_rules: [
        (v) => !!v || 'Pflichtfeld',
        (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
        (v) => (v && v.length === 5) || 'Falsche Anzahl von Zeichen (ungleich 5)',
      ],
      mail_rules: [(v) => !!v || 'Pflichtfeld', (v) => /.+@.+\..+/.test(v) || 'Keine gültige E-Mail Adresse'],
      autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
    },
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      id: '',
      title: '',
      anrede: '',
      name: '',
      vorname: '',
      funktion_id: null,
      street: '',
      plz: '',
      city: '',
      mail: '',
      phone: '',
      zusatz_1: '',
      vereinsbeitritt: '',
      geburtstag: '',
    },
    defaultItem: {
      id: '',
      title: '',
      anrede: '',
      name: '',
      vorname: '',
      funktion_id: '',
      street: '',
      plz: '',
      city: '',
      mail: '',
      phone: '',
      zusatz_1: '',
      vereinsbeitritt: '',
      geburtstag: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Neuer Vereinsfunktionär' : 'Vereinsfunktionär bearbeiten';
    },
    IsLoading() {
      return this.viewmodel.state.is_loading;
    },
    IsDisabled() {
      if (this.$store.state.user.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete();
    },
    menu(val) {
      // eslint-disable-next-line no-unused-expressions, no-return-assign
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  created() {
    this.GetFunktionaereFromCurrentVerein();
  },

  methods: {
    async GetFunktionaereFromCurrentVerein() {
      const { verein_id } = this.$store.state.current_verein;
      const response = await axios.post(
        `${process.env.VUE_APP_APIBASEURL}/verein_get_funktionaere_from_current_verein.php`,
        {
          verein_id,
        },
      );
      this.$store.state.current_verein_funktionaere.data = response.data.result;
    },
    CreateFunktionaere() {
      const data = this.editedItem;
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/verein_create_funktionaere.php`, {
          data,
          verein_id: this.$store.state.current_verein.verein_id,
        })
        .then((response) => {
          this.viewmodel.state.ui.forms.funktionaere.alert = true;
          this.viewmodel.state.ui.forms.funktionaere.alert_type = 'success';
          this.viewmodel.state.ui.forms.funktionaere.response = response.data.message;
          this.GetFunktionaereFromCurrentVerein();
          this.$refs.form_create_or_edit_funktionaer.reset();
        })
        .catch((error) => {
          this.viewmodel.state.ui.forms.funktionaere.alert = true;
          this.viewmodel.state.ui.forms.funktionaere.response = error.response.data.message;
          this.viewmodel.state.ui.forms.funktionaere.alert_type = 'error';
        });
    },
    UpdateFunktionaere() {
      this.viewmodel.state.is_loading = true;
      const data = this.editedItem;
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/verein_update_funktionaere.php`, {
          data,
          verein_id: this.$store.state.current_verein.verein_id,
        })
        .then((response) => {
          this.viewmodel.state.is_loading = false;
          this.viewmodel.state.ui.forms.funktionaere.alert = true;
          this.viewmodel.state.ui.forms.funktionaere.alert_type = 'success';
          this.viewmodel.state.ui.forms.funktionaere.response = response.data.message;
          this.GetFunktionaereFromCurrentVerein();
          this.$refs.form_create_or_edit_funktionaer.reset();
        })
        .catch((error) => {
          this.viewmodel.state.is_loading = false;
          this.viewmodel.state.ui.forms.funktionaere.alert = true;
          this.viewmodel.state.ui.forms.funktionaere.response = error.response.data.message;
          this.viewmodel.state.ui.forms.funktionaere.alert_type = 'error';
        });
    },
    DeleteFunktionaere() {
      const data = this.editedItem;
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/verein_delete_funktionaere.php`, {
          data,
        })
        .then((response) => {
          this.viewmodel.state.ui.forms.funktionaere.alert = true;
          this.viewmodel.state.ui.forms.funktionaere.alert_type = 'success';
          this.viewmodel.state.ui.forms.funktionaere.response = response.data.message;
          this.GetFunktionaereFromCurrentVerein();
          this.$refs.form_create_or_edit_funktionaer.reset();
        })
        .catch((error) => {
          this.viewmodel.state.ui.forms.funktionaere.alert = true;
          this.viewmodel.state.ui.forms.funktionaere.response = error.response.data.message;
          this.viewmodel.state.ui.forms.funktionaere.alert_type = 'error';
        });
    },

    editItem(item) {
      this.viewmodel.state.ui.forms.funktionaere.alert = false;
      this.editedIndex = this.$store.state.current_verein_funktionaere.data.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.viewmodel.state.ui.forms.funktionaere.alert = false;
      this.editedIndex = this.$store.state.current_verein_funktionaere.data.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.state.current_verein_funktionaere.data.splice(this.editedIndex, 1);
      this.DeleteFunktionaere();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$refs.form_create_or_edit_funktionaer.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$refs.form_create_or_edit_funktionaer.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1 && this.$refs.form_create_or_edit_funktionaer.validate()) {
        this.UpdateFunktionaere();
        this.close();
      } else if (this.$refs.form_create_or_edit_funktionaer.validate()) {
        this.CreateFunktionaere();
        this.close();
      }
    },
    save_date(date) {
      this.$refs.menu.save(date);
    },
    save_date_geb(date) {
      this.$refs.menu_geb.save(date);
    },
  },
};
</script>
