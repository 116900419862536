<template>
  <div id="VereinübersichtPanel">
    <v-toolbar dark color="primary" class="mb-4">
      <v-toolbar-title>Vereinsübersicht</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        text
        @click="viewmodel.state.ui.panels.vereinuebersicht.show = !viewmodel.state.ui.panels.vereinuebersicht.show"
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.vereinuebersicht.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.vereinuebersicht.show">
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.vereinuebersicht"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-table</v-icon>
              Vereine anzeigen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Verein anlegen
            </v-tab>

            <v-tab href="#tab-3">
              <v-icon left>mdi-pencil-outline</v-icon>
              IDs bearbeiten
            </v-tab>
            <v-tab href="#tab-4">
              <v-icon left>mdi-delete-outline</v-icon>
              Verein löschen
            </v-tab>
            <v-tab href="#tab-5">
              <v-icon left>mdi-view-list-outline</v-icon>
              Stammdatenauswertung
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="viewmodel.state.ui.tabs.vereinuebersicht" touchless>
            <!-- ---------------------------------- Erster Tab: Vereinsübersicht -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text
                  ><div id="Vereinsübersicht">
                    <v-row>
                      <v-col cols="12"
                        ><v-card flat>
                          <v-card-title>
                            <v-text-field
                              v-model="$store.state.data_tables.vereine.search"
                              append-icon="mdi-magnify"
                              label="Suche"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-card-title>
                          <v-data-table
                            :headers="$store.state.data_tables.vereine.headers"
                            :items="$store.state.data_tables.vereine.data"
                            :search="$store.state.data_tables.vereine.search"
                          >
                            <!-- <template v-slot:item.logo_url="{ item }">
                              <v-img :src="item.logo_url" width="40"></v-img>
                            </template> -->
                          </v-data-table>
                        </v-card></v-col
                      >
                    </v-row>
                  </div></v-card-text
                >
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Verein anlegen -->

            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_verein" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Vereinsname"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.verein_name"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="VereinsID"
                          counter="7"
                          clearable
                          dense
                          prepend-icon="mdi-badge-account-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.verein_id"
                          :rules="viewmodel.form_data.rules.verein_id_rules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Anschrift"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.street"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="PLZ"
                          counter="5"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.plz"
                          :rules="viewmodel.form_data.rules.plz_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Stadt"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-home-city-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.city"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Wiederherstellungs E-Mail"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-email-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.recovery_mail"
                          :rules="viewmodel.form_data.rules.mail_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Kontakt E-Mail Adresse"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-email-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.mail"
                          :rules="viewmodel.form_data.rules.mail_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Kontakt Telefonnummer"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-phone-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.phone"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Homepage"
                          counter="250"
                          clearable
                          dense
                          prepend-icon="mdi-web"
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.homepage_url"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          label="Führungszeugnis liegt vor"
                          dense
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.is_fuehrungszeugnis"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-checkbox
                          label="Teilnahme an Marlefit"
                          dense
                          v-model="viewmodel.form_data.vereinuebersicht.create_verein.data.is_marlefit"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateVerein" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Verein anlegen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_create_verein.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-row
                      ><v-col cols="12">
                        <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="IsLoading">
                        </v-progress-linear></v-col
                    ></v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.vereinuebersicht.create_verein.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.vereinuebersicht.create_verein.alert"
                      >{{ viewmodel.state.ui.forms.vereinuebersicht.create_verein.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: IDs bearbeiten -->

            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_update_verein_id"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          clearable
                          label="Verein auswählen:"
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.update_verein_id.data.old_verein_id"
                          :items="$store.state.data_tables.vereine.data"
                          item-text="verein_name"
                          item-value="verein_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          readonly
                          outlined
                          label="aktuelle VereinsID:"
                          prepend-icon="mdi-badge-account-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.update_verein_id.data.old_verein_id"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          clearable
                          counter="7"
                          label="neue VereinsID:"
                          prepend-icon="mdi-badge-account-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.update_verein_id.data.new_verein_id"
                          :rules="viewmodel.form_data.rules.verein_id_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateVereinID" :disabled="IsDisabled"
                          ><v-icon left>mdi-update</v-icon>ID ändern</v-btn
                        >
                      </v-col></v-row
                    >
                    <v-row>
                      <v-col cols="12"
                        ><v-btn color="error" @click.prevent="$refs.form_update_verein_id.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.alert"
                      >{{ viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Vierter Tab: Verein löschen -->

            <v-tab-item :value="'tab-' + 4">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_verein"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          clearable
                          label="Verein auswählen:"
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.delete_verein.data.verein_id"
                          :items="$store.state.data_tables.vereine.data"
                          item-text="verein_name"
                          item-value="verein_id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          readonly
                          filled
                          label="VereinsID:"
                          v-model="viewmodel.form_data.vereinuebersicht.delete_verein.data.verein_id"
                        ></v-text-field
                      ></v-col>

                      <v-col cols="12" sm="12" md="12" lg="12" xl="12"
                        ><p style="color: red;">
                          <strong>
                            Achtung, das Löschen eines Vereins löscht auch sämtliche Abteilungen, Trainings,
                            Veranstaltungen etc. und ist nicht rückgängig zu machen!</strong
                          >
                        </p></v-col
                      >
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          clearable
                          label="Zum Bestätigen das Wort LÖSCHEN eintragen"
                          :rules="viewmodel.form_data.rules.delete_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteVerein" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Verein löschen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col cols="12"
                        ><v-btn color="error"><v-icon left>mdi-close</v-icon>Abbrechen</v-btn></v-col
                      ></v-row
                    >
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert"
                      >{{ viewmodel.state.ui.forms.vereinuebersicht.delete_verein.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Fünfter Tab: Stammdatenauswertung -->

            <!-- ---------------------------------- Stammdatenauswertung nach Funktionären -->

            <v-tab-item :value="'tab-' + 5">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_stammdatenauswertung"
                    ><h2>Stammdatenauswertung nach Funktionären</h2>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-autocomplete
                          clearable
                          label="Funktionär auswählen:"
                          prepend-icon="mdi-account-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.stammdatenauswertung.funktion_name"
                          :items="$store.state.data_tables.funktionaere.data"
                          item-text="funktion"
                          item-value="funktion"
                          @change="SetAuswertungToFalse"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DoAuswertungFunktionaere"
                          ><v-icon left>mdi-view-list-outline</v-icon>Auswertung starten</v-btn
                        ></v-col
                      ></v-row
                    ><v-row>
                      <v-col cols="12">
                        <v-progress-linear
                          v-if="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung.alert"
                          color="primary"
                          class="mt-4"
                          :size="100"
                          indeterminate
                        >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row v-if="viewmodel.form_data.vereinuebersicht.stammdatenauswertung.auswertung_done">
                      <v-col cols="12">
                        <vue-csv-downloader
                          download-name="Stammdatenauswertung.csv"
                          :data="viewmodel.form_data.vereinuebersicht.stammdatenauswertung.data"
                          ><h3>Auswertung herunterladen</h3></vue-csv-downloader
                        >
                      </v-col></v-row
                    >
                  </v-form>
                  <v-divider class="mt-2"></v-divider>

                  <!-- ---------------------------------- Stammdatenauswertung nach hochgeladenen Dokumenten -->

                  <v-form ref="form_stammdatenauswertung"
                    ><h2 class="mt-4">Stammdatenauswertung nach hochgeladenen Dokumenten</h2>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DoAuswertungDocuments"
                          ><v-icon left>mdi-view-list-outline</v-icon>Auswertung starten</v-btn
                        ></v-col
                      ></v-row
                    ><v-row>
                      <v-col cols="12">
                        <v-progress-linear
                          v-if="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_documents.alert"
                          color="primary"
                          class="mt-4"
                          :size="100"
                          indeterminate
                        >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12">
                        <v-data-table
                          dense
                          v-if="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_documents.auswertung_done"
                          :headers="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_documents.headers"
                          :items="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_documents.data"
                        >
                          <template v-slot:item.logo_url="{ item }">
                            <a :href="item.logo_url" target="blank" v-if="item.logo_url">Ja</a>
                          </template>
                          <template v-slot:item.satzung_url="{ item }">
                            <a :href="item.satzung_url" target="blank" v-if="item.satzung_url">Ja</a>
                          </template>
                          <template v-slot:item.nachweis_gemeinnuetzigkeit_url="{ item }">
                            <a
                              :href="item.nachweis_gemeinnuetzigkeit_url"
                              target="blank"
                              v-if="item.nachweis_gemeinnuetzigkeit_url"
                              >Ja</a
                            >
                          </template>
                          <template v-slot:item.hygiene_concept_url="{ item }">
                            <a :href="item.hygiene_concept_url" target="blank" v-if="item.hygiene_concept_url">Ja</a>
                          </template>
                        </v-data-table></v-col
                      ></v-row
                    >
                    <v-row v-if="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_documents.auswertung_done">
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DoAuswertungDocumentsPDF"
                          ><v-icon left>mdi-cog-outline</v-icon>PDF Version erzeugen</v-btn
                        >
                      </v-col></v-row
                    >
                  </v-form>
                  <v-divider class="mt-2"></v-divider>

                  <!-- ---------------------------------- Stammdatenauswertung nach Sportangeboten -->

                  <v-form ref="form_stammdatenauswertung_angebote"
                    ><h2 class="mt-4">Stammdatenauswertung nach Sportangeboten</h2>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DoAuswertungAngebote"
                          ><v-icon left>mdi-cog-outline</v-icon>Auswertung starten/Dokument generieren</v-btn
                        ></v-col
                      ></v-row
                    >
                  </v-form>
                  <v-divider class="mt-2"></v-divider>

                  <!-- ---------------------------------- Stammdatenauswertung nach Aktualität der Stammdatenpflege -->
                  <v-form ref="form_stammdatenpflege" class="mt-4"
                    ><h2>Vereine auffordern, deren Stammdatenpflege länger als X Monate zurück liegt</h2>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-autocomplete
                          clearable
                          label="Zeitraum auswählen: (Monate)"
                          prepend-icon="mdi-clock-alert-outline"
                          v-model="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.interval"
                          :items="
                            viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.interval
                          "
                          @change="SetAuswertungToFalse"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DoAuswertungStammdatenpflege"
                          ><v-icon left>mdi-view-list-outline</v-icon>Auswertung starten</v-btn
                        ></v-col
                      ></v-row
                    ><v-row>
                      <v-col cols="12">
                        <v-progress-linear
                          v-if="
                            viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege
                              .progress_bar_0
                          "
                          color="primary"
                          class="mt-4"
                          :size="100"
                          indeterminate
                        >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.auswertung_done"
                    >
                      <v-col cols="12">
                        <vue-csv-downloader
                          download-name="Vereinsliste_Stammdatenpflege_ueberfaellig.csv"
                          :data="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.data"
                          ><h3>Auswertung herunterladen</h3></vue-csv-downloader
                        >
                      </v-col></v-row
                    >
                    <v-row
                      ><v-col cols="12"> </v-col
                      ><v-data-table
                        dense
                        v-if="
                          viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.auswertung_done
                        "
                        :headers="
                          viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.headers
                        "
                        :items="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.data"
                      ></v-data-table
                    ></v-row>
                    <v-row
                      v-if="viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.auswertung_done"
                    >
                      <v-col cols="12">
                        <v-btn color="info" @click.prevent="InfoAtVereineSpecific"
                          ><v-icon left>mdi-information-outline</v-icon>Vereine benachrichtigen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row>
                      <v-col cols="12">
                        <v-progress-linear
                          v-if="
                            viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.progress_bar
                          "
                          color="primary"
                          class="mt-4"
                          :size="100"
                          indeterminate
                        >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12">
                        <v-alert
                          border="top"
                          transition="fade-transition"
                          dismissible
                          colored-border
                          :type="
                            viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert_type
                          "
                          elevation="2"
                          v-model="
                            viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert
                          "
                          >{{
                            viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.response
                          }}</v-alert
                        ></v-col
                      ></v-row
                    >
                  </v-form>

                  <!-- Vereine allgemein benachrichtigen ---------- -->
                  <v-divider></v-divider>
                  <h2 class="mt-4">Alle Vereine an die Stammdatenpflege erinnern</h2>
                  <br />
                  <p>
                    Achtung, diese Funktion verschickt eine E-Mail an alle im System registrierten, aktiven Vereine. Je
                    nach Anzahl der Vereine kann das Versenden etwas dauern.
                  </p>
                  <v-row>
                    <v-col cols="12">
                      <v-btn color="info" @click.prevent="InfoAtVereineAllgemein"
                        ><v-icon left>mdi-information-outline</v-icon>Vereine benachrichtigen</v-btn
                      ></v-col
                    ></v-row
                  ><v-row>
                    <v-col cols="12">
                      <v-progress-linear
                        v-if="
                          viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein
                            .progress_bar
                        "
                        color="primary"
                        class="mt-4"
                        :size="100"
                        indeterminate
                      >
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                  <v-row
                    ><v-col cols="12">
                      <v-alert
                        border="top"
                        transition="fade-transition"
                        dismissible
                        colored-border
                        :type="
                          viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein
                            .alert_type
                        "
                        elevation="2"
                        v-model="
                          viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.alert
                        "
                        >{{
                          viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein
                            .response
                        }}</v-alert
                      ></v-col
                    ></v-row
                  >
                  <!-- Geburtstage und Jubiläen ---------- -->
                  <v-divider></v-divider>
                  <h2 class="mt-4">Geburtstage und Jubiläen</h2>
                  <br />
                  <h3>
                    Anstehende Geburtstage
                  </h3>
                  <v-row>
                    <v-col cols="12"
                      ><v-data-table
                        dense
                        :headers="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_geburtstage.headers"
                        :items="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_geburtstage.data"
                      >
                      </v-data-table></v-col
                  ></v-row>
                  <h3>
                    Anstehende Funktionärsjubiläen
                  </h3>
                  <v-row>
                    <v-col cols="12"
                      ><v-data-table
                        dense
                        :headers="
                          viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_jubil_funktionaere.headers
                        "
                        :items="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_jubil_funktionaere.data"
                      >
                      </v-data-table></v-col
                  ></v-row>
                  <h3>
                    Anstehende Vereinsjubiläen (Beitritt zum SSV)
                  </h3>
                  <v-row>
                    <v-col cols="12"
                      ><v-data-table
                        dense
                        :headers="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_jubil_vereine.headers"
                        :items="viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_jubil_vereine.data"
                      >
                      </v-data-table></v-col
                  ></v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import VueCsvDownloader from 'vue-csv-downloader';

const axios = require('axios');

export default {
  name: 'Vereinuebersicht',
  components: {
    VueCsvDownloader,
  },
  data: () => ({
    viewmodel: {
      state: {
        is_loading: false,
        ui: {
          forms: {
            vereinuebersicht: {
              create_verein: {
                show_password: false,
                alert: false,
                alert_type: 'info',
                response: '',
              },
              update_verein_id: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              delete_verein: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  verein_id: '',
                },
              },
              stammdatenauswertung: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  verein_id: '',
                },
              },
              stammdatenauswertung_stammdatenpflege: {
                interval: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                headers: [
                  { text: 'VereinID', width: 100, value: 'verein_id' },
                  { text: 'Vereinsname', width: 350, value: 'verein_name' },
                  { text: 'Letzte Stammdatenänderung', width: 300, value: 'updated_at' },
                  { text: 'E-Mail', width: 200, value: 'mail' },
                  { text: 'Telefon', width: 100, value: 'phone' },
                ],
                alert: false,
                progress_bar: false,
                progress_bar_0: false,
                alert_type: 'info',
                response: '',
                data: {
                  verein_id: '',
                },
              },
              stammdatenauswertung_inform_vereine_allgemein: {
                progress_bar: false,
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  verein_id: '',
                },
              },
              stammdatenauswertung_documents: {
                alert: false,
                progress_bar: false,
                alert_type: 'info',
                response: '',
                headers: [
                  { text: 'VereinID', width: 150, value: 'verein_id' },
                  { text: 'Vereinsname', width: 250, value: 'verein_name' },
                  { text: 'Vereinslogo', value: 'logo_url' },
                  { text: 'Satzung', value: 'satzung_url' },
                  { text: 'Gemeinützigkeitserklärung', value: 'nachweis_gemeinnuetzigkeit_url' },
                  { text: 'Hygienekonzept', value: 'hygiene_concept_url' },
                ],
                data: [],
              },
              stammdatenauswertung_geburtstage: {
                headers: [
                  { text: 'VereinID', width: 150, value: 'verein_id' },
                  { text: 'Vereinsname', width: 250, value: 'verein_name' },
                  { text: 'Funktionär', width: 250, value: 'funktionaer_name' },
                  { text: 'Funktion', width: 250, value: 'funktion' },
                  { text: 'Geburtstag', width: 300, value: 'geburtstag' },
                  { text: 'Anschrift', width: 250, value: 'anschrift' },
                  { text: 'E-Mail', width: 200, value: 'mail' },
                  { text: 'Telefon', width: 200, value: 'phone' },
                ],
                data: [],
              },
              stammdatenauswertung_jubil_funktionaere: {
                headers: [
                  { text: 'VereinID', width: 150, value: 'verein_id' },
                  { text: 'Vereinsname', width: 250, value: 'verein_name' },
                  { text: 'Funktionär', width: 250, value: 'funktionaer_name' },
                  { text: 'Funktion', width: 250, value: 'funktion' },
                  { text: 'Jubiläum', width: 300, value: 'jubilaeum' },
                  { text: 'Anschrift', width: 250, value: 'anschrift' },
                  { text: 'E-Mail', width: 200, value: 'mail' },
                  { text: 'Telefon', width: 200, value: 'phone' },
                ],
                data: [],
              },
              stammdatenauswertung_jubil_vereine: {
                headers: [
                  { text: 'VereinID', width: 150, value: 'verein_id' },
                  { text: 'Vereinsname', width: 250, value: 'verein_name' },
                  { text: 'Jubiläum', width: 300, value: 'jubilaeum' },
                  { text: 'Anschrift', width: 250, value: 'anschrift' },
                  { text: 'E-Mail', width: 200, value: 'mail' },
                  { text: 'Telefon', width: 200, value: 'phone' },
                ],
                data: [],
              },
            },
          },
          panels: {
            vereinuebersicht: {
              show: false,
            },
          },
          tabs: {
            vereinuebersicht: null,
          },
        },
      },
      form_data: {
        rules: {
          generic_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
          ],
          verein_id_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 7) || 'Falsche Anzahl von Zeichen (ungleich 7)',
          ],
          plz_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^[0-9\b]+$/.test(v) || 'Darf keine Buchstaben enthalten',
            (v) => (v && v.length === 5) || 'Falsche Anzahl von Zeichen (ungleich 5)',
          ],
          mail_rules: [
            (v) => !!v || 'Pflichtfeld',
            (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Keine gültige E-Mail Adresse',
          ],
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        vereinuebersicht: {
          create_verein: {
            data: {
              verein_id: '',
              verein_name: '',
              is_verein: 1,
              is_abteilung: 1,
              street: '',
              plz: '',
              city: '',
              logo_url: [],
              mail: '',
              phone: '',
              gemeinnuetzigkeit_url: null,
              homepage_url: '',
              recovery_mail: '',
              is_fuehrungszeugnis: '',
              is_marlefit: '',
            },
          },
          update_verein_id: {
            data: {
              old_verein_id: '',
              new_verein_id: '',
            },
          },
          delete_verein: {
            data: {
              verein_id: '',
            },
          },
          stammdatenauswertung: {
            only_bosses: false,
            funktion_name: null,
            auswertung_done: false,
            data: [],
          },
          stammdatenauswertung_stammdatenpflege: {
            interval: null,
            auswertung_done: false,
            data: [],
          },
          stammdatenauswertung_documents: {
            auswertung_done: false,
          },
        },
      },
    },
  }),
  computed: {
    IsLoading() {
      return this.viewmodel.state.is_loading;
    },
    IsDisabled() {
      if (this.$store.state.current_verein_data.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.DoAuswertungGeburtstage();
    this.DoAuswertungJubilFunktionaere();
    this.DoAuswertungJubilVereine();
  },
  methods: {
    CreateVerein() {
      if (this.$refs.form_create_verein.validate()) {
        this.viewmodel.state.is_loading = true;
        axios
          .post(
            `${process.env.VUE_APP_APIBASEURL}/create_verein.php`,
            this.viewmodel.form_data.vereinuebersicht.create_verein.data,
          )
          .then((response) => {
            this.viewmodel.state.is_loading = false;
            this.viewmodel.state.ui.forms.vereinuebersicht.create_verein.alert = true;
            this.viewmodel.state.ui.forms.vereinuebersicht.create_verein.alert_type = 'success';
            this.viewmodel.state.ui.forms.vereinuebersicht.create_verein.response = response.data.message;
            this.$refs.form_create_verein.reset();
            this.$emit('VereinCreated');
          })
          .catch((error) => {
            this.viewmodel.state.is_loading = false;
            this.viewmodel.state.ui.forms.vereinuebersicht.create_verein.alert = true;
            this.viewmodel.state.ui.forms.vereinuebersicht.create_verein.response = error.response.data.message;
            this.viewmodel.state.ui.forms.vereinuebersicht.create_verein.alert_type = 'error';
          });
      }
    },
    UpdateVereinID() {
      if (this.$refs.form_update_verein_id.validate()) {
        const { data } = this.viewmodel.form_data.vereinuebersicht.update_verein_id;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_verein_id.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.alert = true;
            this.viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.alert_type = 'success';
            this.viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.response = response.data.message;
            if (Number(response.data.old_verein_id) === Number(this.$store.state.current_verein.verein_id)) {
              this.$store.state.current_verein.verein_id = response.data.new_verein_id;
            }
            this.$refs.form_update_verein_id.reset();
            this.$emit('VereinIDUpdated');
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.alert = true;
            this.viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.response = error.response.data.message;
            this.viewmodel.state.ui.forms.vereinuebersicht.update_verein_id.alert_type = 'error';
          });
      }
    },
    DeleteVerein() {
      if (this.$refs.form_delete_verein.validate()) {
        const { data } = this.viewmodel.form_data.vereinuebersicht.delete_verein;
        if (data.verein_id === this.$store.state.user.verein_id) {
          this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert = true;
          this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.response =
            'Verein kann nicht gelöscht werden, Verein ist aktuell angemeldet';
          this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert_type = 'error';
        } else {
          axios
            .post(`${process.env.VUE_APP_APIBASEURL}/delete_verein.php`, data)
            .then((response) => {
              this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert = true;
              this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert_type = 'success';
              this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.response = response.data.message;
              this.$emit('VereinDeleted');
              this.$refs.form_delete_verein.reset();
            })
            .catch((error) => {
              this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert = true;
              this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.response = error.response.data.message;
              this.viewmodel.state.ui.forms.vereinuebersicht.delete_verein.alert_type = 'error';
            });
        }
      }
    },
    DoAuswertungFunktionaere() {
      if (this.$refs.form_stammdatenauswertung.validate()) {
        this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung.alert = true;
        const data = this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung;
        axios.post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_get_stammdaten.php`, data).then((response) => {
          this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung.data = response.data.result;
          this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung.auswertung_done = true;
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung.alert = false;
        });
      }
    },
    DoAuswertungDocuments() {
      this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_documents.progress_bar = true;
      const data = this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_documents;
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_do_auswertung_documents.php`, data)
        .then((response) => {
          this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_documents.data = response.data.result;
          this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_documents.auswertung_done = true;
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_documents.alert = false;
        });
    },
    DoAuswertungGeburtstage() {
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_do_auswertung_geburtstage.php`)
        .then((response) => {
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_geburtstage.data = response.data.result;
        });
    },
    DoAuswertungJubilFunktionaere() {
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_do_auswertung_jubil_funktionaere.php`)
        .then((response) => {
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_jubil_funktionaere.data =
            response.data.result;
        });
    },
    DoAuswertungJubilVereine() {
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_do_auswertung_jubil_vereine.php`)
        .then((response) => {
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_jubil_vereine.data = response.data.result;
        });
    },
    DoAuswertungDocumentsPDF() {
      axios({
        url: `${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_do_auswertung_documents_pdf.php`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        const date = new Date();
        const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${'Stammdatenauswertung nach hochgeladenen Dokumenten '}${today.toString()}.pdf`,
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    DoAuswertungAngebote() {
      axios({
        url: `${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_do_auswertung_angebote.php`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        const date = new Date();
        const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${'Stammdatenauswertung nach Sportangeboten '}${today.toString()}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    DoAuswertungStammdatenpflege() {
      if (this.$refs.form_stammdatenpflege.validate()) {
        this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.progress_bar_0 = true;
        const data = this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_get_stammdatenpflege.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert = false;
            this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.data = response.data.result;
            this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.auswertung_done = true;
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.progress_bar_0 = false;
          });
      }
    },
    InfoAtVereineSpecific() {
      if (this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.data.length > 0) {
        this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.progress_bar = true;
        const { data } = this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_inform_vereine_specific.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert = true;
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert_type = 'success';
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.response =
              response.data.message;
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.progress_bar = false;
            this.$emit('VereineInformiertSpecific');
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.progress_bar = false;
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert = true;
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.response =
              error.response.data.message;
            this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert_type = 'error';
          });
      } else {
        this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.progress_bar = false;
        this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert = true;
        this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.response =
          'Keine Vereine gefunden, die den Kriterien entsprechen.';
        this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert_type = 'error';
      }
    },
    InfoAtVereineAllgemein() {
      this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.progress_bar = true;
      const { data } = this.$store.state.data_tables.vereine;
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/vereinuebersicht_inform_vereine_allgemein.php`, data)
        .then((response) => {
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.alert = true;
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.alert_type =
            'success';
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.response =
            response.data.message;
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.progress_bar = false;
          this.$emit('VereineInformiertSpecific');
        })
        .catch((error) => {
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.progress_bar = false;
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.alert = true;
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.response =
            error.response.data.message;
          this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_inform_vereine_allgemein.alert_type =
            'error';
        });
    },
    SetAuswertungToFalse() {
      this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung.auswertung_done = false;
      this.viewmodel.state.ui.forms.vereinuebersicht.stammdatenauswertung_stammdatenpflege.alert = false;
      this.viewmodel.form_data.vereinuebersicht.stammdatenauswertung_stammdatenpflege.auswertung_done = false;
    },
  },
};
</script>
