<template>
  <div id="SportartenübersichtPanel">
    <v-toolbar dark color="primary" class="mb-4">
      <v-toolbar-title>Sportarten</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        text
        @click="viewmodel.state.ui.panels.sportarten.show = !viewmodel.state.ui.panels.sportarten.show"
        ><v-icon size="30">{{
          viewmodel.state.ui.panels.sportarten.show ? 'mdi-menu-up' : 'mdi-menu-down'
        }}</v-icon></v-btn
      >
    </v-toolbar>
    <div v-show="viewmodel.state.ui.panels.sportarten.show">
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="viewmodel.state.ui.tabs.sportarten"
            color="primary"
            background-color="transparent"
            centered
            grow
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon left>mdi-table</v-icon>
              Sportarten anzeigen
            </v-tab>

            <v-tab href="#tab-2">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Sportart anlegen
            </v-tab>

            <v-tab href="#tab-3">
              <v-icon left>mdi-pencil-outline</v-icon>
              Sportart bearbeiten
            </v-tab>
            <v-tab href="#tab-4">
              <v-icon left>mdi-delete-outline</v-icon>
              Sportart löschen
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="viewmodel.state.ui.tabs.sportarten">
            <!-- ---------------------------------- Erster Tab: Sportartenübersicht -->
            <v-tab-item :value="'tab-' + 1">
              <v-card flat>
                <v-card-text
                  ><div id="Sportarten">
                    <v-row>
                      <v-col cols="12"
                        ><v-card flat>
                          <v-card-title>
                            <v-text-field
                              v-model="$store.state.data_tables.sportarten.search"
                              append-icon="mdi-magnify"
                              label="Suche"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-card-title>
                          <v-data-table
                            :headers="$store.state.data_tables.sportarten.headers"
                            :items="$store.state.data_tables.sportarten.data"
                            :search="$store.state.data_tables.sportarten.search"
                          >
                            <template v-slot:item.img_url="{ item }">
                              <v-img :src="item.img_url" width="40"></v-img>
                            </template>
                          </v-data-table> </v-card
                      ></v-col>
                    </v-row></div
                ></v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Zweiter Tab: Sportart anlegen -->

            <v-tab-item :value="'tab-' + 2">
              <v-card flat>
                <v-card-text>
                  <v-form ref="form_create_sportart">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Sportart:"
                          counter="45"
                          clearable
                          dense
                          prepend-icon="mdi-spellcheck"
                          v-model="viewmodel.form_data.sportarten.create_sportart.data.sportart_name"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-file-input
                          label="Piktogramm (optional)"
                          dense
                          truncate-length="15"
                          accept="image/*"
                          v-model="viewmodel.form_data.sportarten.create_sportart.data.img"
                        ></v-file-input></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="CreateSportart" :disabled="IsDisabled"
                          ><v-icon left>mdi-check</v-icon>Sportart anlegen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col
                        ><v-btn color="error" @click.prevent="$refs.form_create_sportart.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                    <v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.sportarten.create_sportart.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.sportarten.create_sportart.alert"
                      >{{ viewmodel.state.ui.forms.sportarten.create_sportart.response }}</v-alert
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Dritter Tab: Sportart bearbeiten -->

            <v-tab-item :value="'tab-' + 3">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_update_sportart"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          clearable
                          label="Sportart auswählen"
                          prepend-icon="mdi-spellcheck"
                          v-model="viewmodel.form_data.sportarten.update_sportart.data.id"
                          :items="$store.state.data_tables.sportarten.data"
                          item-text="sportart_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                          @change="GetSportartByID"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"></v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                          label="Neuer Name"
                          clearable
                          prepend-icon="mdi-spellcheck"
                          counter="45"
                          v-model="viewmodel.form_data.sportarten.update_sportart.data.sportart_name"
                          :rules="viewmodel.form_data.rules.generic_rules"
                        ></v-text-field></v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-file-input
                          label="Piktogramm (optional)"
                          dense
                          truncate-length="15"
                          accept="image/*"
                          v-model="viewmodel.form_data.sportarten.update_sportart.data.img"
                        ></v-file-input></v-col
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-img
                          max-width="40"
                          :src="viewmodel.form_data.sportarten.update_sportart.data.img_url"
                        ></v-img></v-col
                    ></v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="UpdateSportart" :disabled="IsDisabled"
                          ><v-icon left>mdi-update</v-icon>Änderungen speichern</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col
                        ><v-btn color="error" @click.prevent="$refs.form_update_sportart.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    ><v-alert
                      border="top"
                      transition="fade-transition"
                      dismissible
                      colored-border
                      :type="viewmodel.state.ui.forms.sportarten.update_sportart.alert_type"
                      elevation="2"
                      v-model="viewmodel.state.ui.forms.sportarten.update_sportart.alert"
                      >{{ viewmodel.state.ui.forms.sportarten.update_sportart.response }}</v-alert
                    >
                  </v-form></v-card-text
                >
              </v-card>
            </v-tab-item>

            <!-- ---------------------------------- Vierter Tab: Sportart löschen -->

            <v-tab-item :value="'tab-' + 4">
              <v-card flat>
                <v-card-text
                  ><v-form ref="form_delete_sportart"
                    ><v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><v-autocomplete
                          clearable
                          label="Sportart auswählen:"
                          prepend-icon="mdi-format-list-bulleted"
                          v-model="viewmodel.form_data.sportarten.delete_sportart.data.id"
                          :items="$store.state.data_tables.sportarten.data"
                          item-text="sportart_name"
                          item-value="id"
                          :rules="viewmodel.form_data.rules.autocomplete_rules"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="success" @click.prevent="DeleteSportart" :disabled="IsDisabled"
                          ><v-icon left>mdi-delete-outline</v-icon>Sportart löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col
                        ><v-btn color="error" @click.prevent="$refs.form_delete_sportart.reset()"
                          ><v-icon left>mdi-close</v-icon>Abbrechen</v-btn
                        ></v-col
                      ></v-row
                    >
                  </v-form>
                  <v-alert
                    border="top"
                    transition="fade-transition"
                    dismissible
                    colored-border
                    :type="viewmodel.state.ui.forms.sportarten.delete_sportart.alert_type"
                    elevation="2"
                    v-model="viewmodel.state.ui.forms.sportarten.delete_sportart.alert"
                    >{{ viewmodel.state.ui.forms.sportarten.delete_sportart.response }}</v-alert
                  ></v-card-text
                >
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Sportartenuebersicht',
  data: () => ({
    viewmodel: {
      state: {
        ui: {
          forms: {
            sportarten: {
              create_sportart: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
              update_sportart: {
                alert: false,
                alert_type: 'info',
                response: '',
                data: {
                  id: '',
                },
              },
              delete_sportart: {
                alert: false,
                alert_type: 'info',
                response: '',
              },
            },
          },
          panels: {
            sportarten: {
              show: false,
            },
          },
          tabs: {
            sportarten: null,
          },
        },
      },
      form_data: {
        rules: {
          autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
          delete_rules: [(v) => !!v || 'Pflichtfeld', (v) => (v && v === 'LÖSCHEN') || 'Bestätigung nicht korrekt'],
        },
        sportarten: {
          create_sportart: {
            data: {
              sportart_name: '',
              img: null,
            },
          },
          update_sportart: {
            data: {
              id: '',
              img: null,
              sportart_name: '',
            },
          },
          delete_sportart: {
            data: {
              id: '',
            },
          },
        },
      },
    },
  }),
  computed: {
    IsDisabled() {
      if (this.$store.state.current_verein_data.verein_id === '1000001') {
        return true;
      }
      return false;
    },
  },
  methods: {
    CreateSportart() {
      if (this.$refs.form_create_sportart.validate()) {
        const { data } = this.viewmodel.form_data.sportarten.create_sportart;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.sportarten.create_sportart.data.img);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/create_sportart.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.sportarten.create_sportart.alert = true;
            this.viewmodel.state.ui.forms.sportarten.create_sportart.alert_type = 'success';
            this.viewmodel.state.ui.forms.sportarten.create_sportart.response = response.data.message;
            this.$emit('SportartCreated');
            this.$refs.form_create_sportart.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.sportarten.create_sportart.alert = true;
            this.viewmodel.state.ui.forms.sportarten.create_sportart.response = error.response.data.message;
            this.viewmodel.state.ui.forms.sportarten.create_sportart.alert_type = 'error';
          });
      }
    },
    UpdateSportart() {
      if (this.$refs.form_update_sportart.validate()) {
        const { data } = this.viewmodel.form_data.sportarten.update_sportart;
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        const formData = new FormData();
        formData.append('form_data', blob);
        formData.append('file', this.viewmodel.form_data.sportarten.update_sportart.data.img);
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/update_sportart.php`, formData)
          .then((response) => {
            this.viewmodel.state.ui.forms.sportarten.update_sportart.alert = true;
            this.viewmodel.state.ui.forms.sportarten.update_sportart.alert_type = 'success';
            this.viewmodel.state.ui.forms.sportarten.update_sportart.response = response.data.message;
            this.$emit('SportartUpdated');
            this.$refs.form_update_sportart.reset();
            this.viewmodel.form_data.sportarten.update_sportart.data.img_url = '';
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.sportarten.update_sportart.alert = true;
            this.viewmodel.state.ui.forms.sportarten.update_sportart.response = error.response.data.message;
            this.viewmodel.state.ui.forms.sportarten.update_sportart.alert_type = 'error';
          });
      }
    },
    DeleteSportart() {
      if (this.$refs.form_delete_sportart.validate()) {
        const { data } = this.viewmodel.form_data.sportarten.delete_sportart;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/delete_sportart.php`, data)
          .then((response) => {
            this.viewmodel.state.ui.forms.sportarten.delete_sportart.alert = true;
            this.viewmodel.state.ui.forms.sportarten.delete_sportart.alert_type = 'success';
            this.viewmodel.state.ui.forms.sportarten.delete_sportart.response = response.data.message;
            this.$emit('SportartDeleted');
            this.$refs.form_delete_sportart.reset();
          })
          .catch((error) => {
            this.viewmodel.state.ui.forms.sportarten.delete_sportart.alert = true;
            this.viewmodel.state.ui.forms.sportarten.delete_sportart.response = this.GetInUseArray(
              error.response.data.message,
              error.response.data.result,
            );
            this.viewmodel.state.ui.forms.sportarten.delete_sportart.alert_type = 'error';
          });
      }
    },
    async GetSportartByID() {
      const { data } = this.viewmodel.form_data.sportarten.update_sportart;
      if (data.id) {
        const response = await axios.post(
          `${process.env.VUE_APP_APIBASEURL}/sportartenuebersicht_get_sportart_by_id.php`,
          data,
        );
        // eslint-disable-next-line prefer-destructuring
        this.viewmodel.form_data.sportarten.update_sportart.data = response.data.result[0];
      }
    },
    GetInUseArray(response, result) {
      let verein = '';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < result.length; i++) {
        if (result.length - i > 1) {
          verein += `${result[i].verein_name},\n`;
        } else {
          verein += `${result[i].verein_name}.\n`;
        }
      }
      return `${response}\n${verein}`;
    },
  },
};
</script>
